import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: start;
`;

export const HeaderContainer = styled.h5`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
`;

export const ValueContainer = styled.h2`
    font-size: 36px;
    font-weight: 600;
    line-height: 1.25;
    letter-spacing: 2px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    ${(props) => props.size === 'small'
        && `
            font-size: 28px;
        `};
    ${(props) => props.size === 'x-small'
        && `
            font-size: 24px;
            letter-spacing: 1px;
            line-height: 1;
        `};
    ${(props) => props.variant === 'error'
        && `
            color: ${props.theme.rainbow.palette.error.main};
        `};
`;
