import firebase from '../../../firebase';
import { getFunctionName } from '../../../helpers';

const createStripePaymentIntent = firebase.functions().httpsCallable(getFunctionName('createStripePaymentIntent'));

const getPaymentIntent = async ({ amount, paymentMethod }) => {
    const { data } = await createStripePaymentIntent({
        amount,
        paymentMethod,
    });
    return data;
};

export default getPaymentIntent;
