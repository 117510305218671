import firebaseInstance from '../../../firebase';
import { getFirestorePath } from '../../../helpers';

export default async function getContacts(uid, groupId, query) {
    const ref = firebaseInstance.firestore().collection(getFirestorePath(`users/${uid}/groups/${groupId}/contacts`));
    const finalQuery = query ? query(ref) : ref;
    const contactsCollection = await finalQuery.get();
    return contactsCollection.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
    }));
}
