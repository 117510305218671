import styled from 'styled-components';
import Unknown from '../icons/unknown';

export const Container = styled.span`
    display: inline-block;
    margin-right: 12px;
`;

export const Icon = styled(Unknown)`
    width: 45px;
    height: 32px;
`;
