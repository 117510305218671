import styled from 'styled-components';
import {
    Button, Table, Input,
} from 'react-rainbow-components';
import CsvFileIcon from '../../../components/icons/csv';
import ErrorCircleIcon from '../../../components/icons/errorCircle';
import InfoCircleIcon from '../../../components/icons/infoCircle';

export const Container = styled.section`
    width: 100%;
    padding: 8px 0;
    display: flex;
    height: 100%;
    overflow: auto;
`;

export const GroupCard = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: auto;
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 32px 16px 32px;
`;

export const Title = styled.h2`
    font-size: 20px;
    line-height: 1.25;
    color: ${(props) => props.theme.rainbow.palette.text.main};
`;

export const GroupTable = styled(Table)`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: auto;
    margin-bottom: 12px;
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 8px 0;
    align-items: center;
    width: 100%;
    height: 100%;
`;

export const Content = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const ImportContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

export const ImportLabel = styled.h1`
    font-size: 14px;
    margin-bottom: 2px;
`;

export const InputName = styled(Input)`
    width: 100%;
`;

export const ImportContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f8f9fa;
    border: 1px solid rgba(227, 229, 237, 0.25);
    width: 100%;
    border-radius: 22px;
    padding: 2px;
`;

export const LeftContent = styled.div`
    display: flex;
    align-items: center;
`;

export const ImportText = styled.h2`
    font-size: 16px;
    color: rgba(63, 73, 84, 1);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export const ImportButton = styled(Button)`
    flex-shrink: 0;
`;

export const ImportIcon = styled(CsvFileIcon)`
    width: 28px;
    height: 28px;
    margin: 0 8px;
`;

export const ImportedContent = styled(ImportContent)`
    padding: 8px 0;
    border-radius: 14px;
`;

export const ImportIconLarge = styled(ImportIcon)`
    width: 42px;
    height: 42px;
`;

export const CreateButton = styled(Button)`
    align-self: flex-end;
    margin-top: 48px;
`;

export const ImportHelpText = styled.h4`
    font-size: 12px;
    color: #929ba5;
    text-align: center;
    margin-top: 4px;
`;

export const ImportDescription = styled.h3`
    font-size: 14px;
    color: #a4a7b5;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
`;

export const StatusContainer = styled.div`
    display: flex;
    align-items: center;
    margin-right: 12px;
`;

export const StatusIcon = styled(ErrorCircleIcon)`
    width: 12px;
    height: 12px;
    margin-right: 4px;
`;

export const StatusText = styled.h5`
    font-size: 12px;
    color: #929ba5;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export const InfoContainer = styled.div`
    border-radius: 20px;
    padding: 8px;
    display: flex;
    background-color: #f8f9fa;
    margin: 0 12px;
    width: fit-content;
    align-items: center;
`;

export const InfoIcon = styled(InfoCircleIcon)`
    width: 16px;
    height: 16px;
    margin-right: 8px;
`;

export const InfoText = styled.h1`
    font-size: 14px;
    margin-right: 8px;
`;
