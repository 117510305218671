import React from 'react';
import propTypes from 'prop-types';
import { FormattedDate } from 'react-intl';

const FormattedDateCol = ({ value }) => (
    <FormattedDate
        value={value.toDate()}
        year="numeric"
        month="numeric"
        day="numeric"
        hour="numeric"
        minute="numeric"
    />
);

FormattedDateCol.propTypes = {
    value: propTypes.object.isRequired,
};

export default FormattedDateCol;
