import React from 'react';
import PropTypes from 'prop-types';

const ArrowRight = (props) => {
    const { className } = props;
    return (
        <svg className={className} width="18px" height="13px" viewBox="0 0 18 13" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <g id="Groups" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path d="M6.14890909,0.550363636 C6.39109091,0.308181818 6.77236364,0.308181818 7.01454545,0.550363636 C7.24854545,0.784363636 7.24854545,1.17381818 7.01454545,1.40727273 L2.62527273,5.79654545 L17.1092727,5.79654545 C17.4469091,5.79654545 17.724,6.06490909 17.724,6.40254545 C17.724,6.74018182 17.4469091,7.01727273 17.1092727,7.01727273 L2.62527273,7.01727273 L7.01454545,11.3983636 C7.24854545,11.6405455 7.24854545,12.0305455 7.01454545,12.264 C6.77236364,12.5061818 6.39109091,12.5061818 6.14890909,12.264 L0.720545455,6.83563636 C0.486545455,6.60163636 0.486545455,6.21218182 0.720545455,5.97872727 L6.14890909,0.550363636 Z" id="Shape" fillRule="nonzero" fill="currentColor" transform="translate(9.134523, 6.407182) scale(-1, 1) translate(-9.134523, -6.407182)" />
            </g>
        </svg>
    );
};

ArrowRight.propTypes = {
    className: PropTypes.string,
};
ArrowRight.defaultProps = {
    className: undefined,
};

export default ArrowRight;
