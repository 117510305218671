import React from 'react';
import PropTypes from 'prop-types';

const Discover = (props) => {
    const { className } = props;
    return (
        <svg className={className} width="45px" height="32px" viewBox="0 0 45 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <g id="mensajes" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="discover" fillRule="nonzero">
                    <path d="M42.1518125,0.125 C42.84839,0.125 43.4788957,0.411722889 43.9353205,0.875017864 C44.3924376,1.3390155 44.675,1.98006831 44.675,2.68804821 L44.675,2.68804821 L44.675,28.671863 C44.675,29.3798713 44.3924284,30.0209341 43.9353174,30.4849409 C43.4788802,30.9482637 42.8483631,31.235 42.1518125,31.235 L42.1518125,31.235 L2.6481875,31.235 C1.95163687,31.235 1.32111982,30.9482637 0.864682618,30.4849409 C0.407571648,30.0209341 0.125,29.3798713 0.125,28.671863 L0.125,28.671863 L0.125,2.68804821 C0.125,1.9800474 0.407566008,1.33901185 0.864669294,0.87502821 C1.32110791,0.411719251 1.95163058,0.125 2.6481875,0.125 L2.6481875,0.125 Z" id="Shape" stroke="#E3E5ED" strokeWidth="0.25" fill="#FFFFFF" />
                    <g id="Group-43" transform="translate(6.000000, 11.000000)">
                        <circle id="Oval" fill="#F26E21" cx="18.3139223" cy="2.87194346" r="2.86459364" />
                        <g id="Group-42">
                            <g id="Group" fill="#0B161F">
                                <path d="M2.88544876,4.24360424 C2.54790813,4.54577385 2.11233922,4.67678445 1.42062898,4.67678445 L1.13361837,4.67678445 L1.13361837,1.08033216 L1.42062898,1.08033216 C2.11224735,1.08033216 2.53063604,1.20270671 2.88544876,1.52031095 C3.25532862,1.84655124 3.47600707,2.35157597 3.47600707,2.87341343 C3.47600707,3.3955265 3.25532862,3.91837456 2.88544876,4.24360424 L2.88544876,4.24360424 Z M1.63515194,0.15930742 L0.0634840989,0.15930742 L0.0634840989,5.59698233 L1.62725088,5.59698233 C2.45713781,5.59698233 3.05697527,5.40239576 3.58331449,4.96930742 C4.20759011,4.45573852 4.57820495,3.68125088 4.57820495,2.88057951 C4.57811307,1.27519435 3.36879152,0.15930742 1.63515194,0.15930742" id="Shape" />
                                <polyline id="Path" points="5.07137809 5.59698233 6.13985866 5.59698233 6.13985866 0.15930742 5.07137809 0.15930742 5.07137809 5.59698233" />
                                <path d="M8.7555689,2.24527915 C8.11337809,2.0090742 7.92430389,1.85371731 7.92430389,1.56156184 C7.92430389,1.21878445 8.26083392,0.957865724 8.72185159,0.957865724 C9.04257951,0.957865724 9.30524382,1.08814134 9.58609894,1.39720141 L10.1440424,0.672508834 C9.68412721,0.272402827 9.13334982,0.0688127208 8.53250177,0.0688127208 C7.56306007,0.0688127208 6.82256537,0.737462898 6.82256537,1.62504594 C6.82256537,2.37619788 7.16736396,2.75912367 8.17116608,3.11871378 C8.59056537,3.26479152 8.80371025,3.36208481 8.9109258,3.42841696 C9.12443816,3.56732862 9.2320212,3.76219081 9.2320212,3.9904947 C9.2320212,4.43157597 8.87831095,4.75708127 8.40075618,4.75708127 C7.89095406,4.75708127 7.48019081,4.50479859 7.23332862,4.03174558 L6.54373145,4.69277032 C7.03598587,5.40919435 7.62801413,5.72854417 8.44265018,5.72854417 C9.55228975,5.72854417 10.3330252,4.99356184 10.3330252,3.94235336 C10.3334841,3.07755477 9.9725159,2.68599293 8.7555689,2.24527915" id="Path" />
                                <path d="M10.6703816,2.88057951 C10.6703816,4.4804523 11.9368481,5.71972438 13.5649258,5.71972438 C14.025576,5.71972438 14.4195265,5.62932155 14.905258,5.40239576 L14.905258,4.1535689 C14.4773145,4.57811307 14.0987986,4.74899647 13.6137102,4.74899647 C12.5366855,4.74899647 11.7718445,3.97414134 11.7718445,2.87304594 C11.7718445,1.82973852 12.5611237,1.00573145 13.5649258,1.00573145 C14.0737173,1.00573145 14.4607774,1.18479152 14.905258,1.61732862 L14.905258,0.369420495 C14.4370742,0.133583039 14.0496466,0.0362897527 13.5889965,0.0362897527 C11.9697385,0.0366572438 10.6703816,1.30064311 10.6703816,2.88057951" id="Path" />
                                <polyline id="Path" points="23.5705159 3.81189399 22.1067067 0.15930742 20.9392792 0.15930742 23.2669682 5.73626148 23.8417244 5.73626148 26.2102968 0.15930742 25.0507703 0.15930742 23.5705159 3.81189399" />
                                <polyline id="Path" points="26.6960283 5.59698233 29.7293922 5.59698233 29.7293922 4.67678445 27.7647845 4.67678445 27.7647845 3.20755477 29.6541484 3.20755477 29.6541484 2.28726502 27.7647845 2.28726502 27.7647845 1.08024028 29.7293922 1.08024028 29.7293922 0.15930742 26.6960283 0.15930742 26.6960283 5.59698233" />
                                <path d="M31.816742,2.66228975 L31.5052933,2.66228975 L31.5052933,1.01427562 L31.8335548,1.01427562 C32.5008269,1.01427562 32.8628057,1.29274205 32.8628057,1.82174558 C32.8628057,2.36765371 32.5008269,2.66228975 31.816742,2.66228975 L31.816742,2.66228975 Z M33.962523,1.76404947 C33.962523,0.74545583 33.2561131,0.15930742 32.0219859,0.15930742 L30.4355265,0.15930742 L30.4355265,5.59698233 L31.5050177,5.59698233 L31.5050177,3.41169611 L31.6453074,3.41169611 L33.1241837,5.59698233 L34.4398021,5.59698233 L32.7124099,3.30613428 C33.5194205,3.14324382 33.962523,2.59632509 33.962523,1.76404947" id="Shape" />
                            </g>
                            <polygon id="Path" fill="#000000" points="7.86522968 10.6510883 7.86522968 7.59190813 9.9539576 9.77719435 9.9539576 7.78640283 10.3864028 7.78640283 10.3864028 10.8252792 8.29767491 8.64513781 8.29767491 10.650629 7.86522968 10.650629" />
                            <polygon id="Path" fill="#000000" points="12.6535477 8.19284806 11.5050459 8.19284806 11.5050459 8.88106714 12.6201979 8.88106714 12.6201979 9.28769611 11.5050459 9.28769611 11.5050459 10.244735 12.6535477 10.244735 12.6535477 10.6510883 11.0728763 10.6510883 11.0728763 7.78686219 12.6535477 7.78686219 12.6535477 8.19284806" />
                            <polygon id="Path" fill="#000000" points="14.0114276 8.19284806 14.0114276 10.6508127 13.5789823 10.6508127 13.5789823 8.19284806 12.9207138 8.19284806 12.9207138 7.7864947 14.6684099 7.7864947 14.6684099 8.19284806" />
                            <polygon id="Path" fill="#000000" points="15.3658163 7.78686219 16.1118233 9.70130742 16.8914558 7.64767491 17.6370954 9.70130742 18.4291307 7.78686219 18.8986926 7.78686219 17.6185371 10.8329965 16.8818092 8.80343463 16.1138445 10.8351095 14.89653 7.78695406" />
                            <path d="M18.9815618,9.20620495 C18.9815618,8.80260777 19.1297527,8.4564311 19.4250318,8.16730742 C19.7193004,7.87781625 20.0733781,7.73311661 20.4866219,7.73311661 C20.894629,7.73311661 21.2446643,7.87891873 21.5364523,8.17070671 C21.8297102,8.46295406 21.975788,8.81317314 21.975788,9.22301767 C21.975788,9.6345159 21.8286996,9.98409187 21.5347067,10.2711943 C21.2390601,10.5593074 20.8822261,10.7036396 20.4642049,10.7036396 C20.0946926,10.7036396 19.7622049,10.5753852 19.4679364,10.3196113 C19.1438092,10.0360919 18.9815618,9.66483392 18.9815618,9.20620495 Z M19.4177739,9.21070671 C19.4177739,9.52693286 19.5243463,9.78684099 19.7367562,9.9910742 C19.9481555,10.1953074 20.192629,10.2970106 20.4699929,10.2970106 C20.7701413,10.2970106 21.0238021,10.1931943 21.230424,9.98592933 C21.4370459,9.77590813 21.5401272,9.52013428 21.5401272,9.21860777 C21.5401272,8.91303887 21.4380565,8.65726502 21.2338233,8.45128622 C21.0309682,8.24365371 20.7800636,8.13983746 20.4806502,8.13983746 C20.1826148,8.13983746 19.9309753,8.24365371 19.7260989,8.45128622 C19.5205795,8.65551943 19.4177739,8.90918021 19.4177739,9.21070671 Z" id="Shape" fill="#000000" />
                            <path d="M23.4897597,9.43037456 L24.3766996,10.6510883 L23.8479717,10.6510883 L23.0298445,9.47888339 L22.9518445,9.47888339 L22.9518445,10.6510883 L22.5193993,10.6510883 L22.5193993,7.78686219 L23.0258021,7.78686219 C23.4045936,7.78686219 23.6775477,7.8580636 23.8460424,8.00037456 C24.0317173,8.15848763 24.1245088,8.36749823 24.1245088,8.62740636 C24.1245088,8.83026148 24.0664452,9.00454417 23.9498587,9.15025442 C23.8334558,9.29596466 23.6804876,9.38976678 23.4897597,9.43037456 Z M22.9520283,9.1020212 L23.0888269,9.1020212 C23.498212,9.1020212 23.7031802,8.94556184 23.7031802,8.63273498 C23.7031802,8.33947703 23.5040919,8.1930318 23.1057314,8.1930318 L22.9520283,8.1930318 L22.9520283,9.1020212 L22.9520283,9.1020212 Z" id="Shape" fill="#000000" />
                            <polygon id="Path" fill="#000000" points="25.239477 8.9294841 26.3543534 7.78677032 26.9314982 7.78677032 25.6365512 9.08383039 26.9391237 10.6510883 26.3602332 10.6510883 25.3285936 9.37672085 25.2395689 9.46611307 25.2395689 10.6510883 24.8071237 10.6510883 24.8071237 7.78686219 25.2395689 7.78686219 25.2395689 8.9294841" />
                        </g>
                        <path d="M38.6043958,5.85624735 C34.5098092,9.71921493 24.9203407,17.0181863 8.01877888,20.0988838 C8.01877888,20.0988838 32.9000154,20.0988838 36.0310401,20.0988838 C38.241592,20.0988838 38.6043958,18.0733018 38.6043958,17.3253507 C38.6043958,17.3253507 38.6043958,7.01866254 38.6043958,5.85624735 Z" id="Path" fill="#F26E21" />
                    </g>
                </g>
            </g>
        </svg>
    );
};

Discover.propTypes = {
    className: PropTypes.string,
};
Discover.defaultProps = {
    className: undefined,
};

export default Discover;
