import React from 'react';
import PropTypes from 'prop-types';
import { Container, Label, StyledLink } from './styled';

export default function LinkOutput(props) {
    const {
        className,
        style,
        label,
        value,
        to,
    } = props;
    return (
        <Container className={className} style={style}>
            <Label>
                {label}
            </Label>
            <StyledLink to={to} value={value} />
        </Container>
    );
}

LinkOutput.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    label: PropTypes.node,
    value: PropTypes.node,
    to: PropTypes.string,
};

LinkOutput.defaultProps = {
    className: undefined,
    style: {},
    label: null,
    value: null,
    to: null,
};
