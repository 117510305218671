import styled from 'styled-components';
import { Card } from 'react-rainbow-components';

export const ContainerCard = styled(Card)`
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 12px;
`;

export const IconContainer = styled.div`
    width: 74px;
    height: 74px;
    display: flex;
    justify-content: center;
    align-items: center;
    ${(props) => props.size === 'small'
        && `
            width: 56px;
            height: 56px;
        `};
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const Label = styled.h2`
    font-size: 0.875rem;
    letter-spacing: 0.3px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
`;

export const ValueContainer = styled.div`
    display: flex;
    align-items: flex-end;
`;

export const Value = styled.h1`
    font-size: 3rem;
    line-height: 1.07;
    letter-spacing: 1.8px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    ${(props) => props.size === 'small'
        && `
            font-size: 2rem;
        `};
    ${(props) => props.variant === 'error'
        && `
            color: ${props.theme.rainbow.palette.error.main};
        `};
`;
