import React from 'react';
import PropTypes from 'prop-types';
import {
    Badge,
    Column,
} from 'react-rainbow-components';
import { useCollectionOnce } from '@rainbow-modules/firebase-hooks';
import getCurrentUser from '../../../redux/services/firebase/get-current-user';
import FormattedDate from '../../../components/formatted-date-col';
import StatusBadge from '../../../components/status-badge';
import {
    Container,
    MessagesTable,
    TableCard,
    Title,
} from './styled';
import Chart from './chart';
import { getFirestorePath } from '../../../helpers';

// eslint-disable-next-line react/prop-types
const TypeBadge = ({ value }) => <Badge label={value} />;

// eslint-disable-next-line react/prop-types
const TagBadge = ({ value }) => {
    if (value) {
        return <Badge className="rainbow-m-right_medium" label={value} />;
    }
    return null;
};

function query(ref) {
    return ref.limit(50).orderBy('createdAt', 'desc');
}

function Dashboard(props) {
    const {
        className,
        style,
    } = props;

    const [messages, isLoading] = useCollectionOnce({
        path: getFirestorePath(`users/${getCurrentUser().uid}/messages`),
        query,
    });

    return (
        <Container className={className} style={style}>
            <Chart />
            <TableCard>
                <Title className="rainbow-m-left_x-large rainbow-m-top_medium">Last 50 Messages</Title>
                <MessagesTable keyField="id" showRowNumberColumn isLoading={isLoading} data={messages}>
                    <Column header="Date" field="data.createdAt" width={180} component={FormattedDate} />
                    <Column header="Type" field="data.type" width={150} component={TypeBadge} />
                    <Column header="From" field="data.from" width={150} />
                    <Column header="To" field="data.to" width={150} />
                    <Column header="Status" field="data.status" width={150} component={StatusBadge} />
                    <Column header="Message" field="data.body" title="data.body" />
                    <Column header="Tags" field="data.tags" component={TagBadge} />
                </MessagesTable>
            </TableCard>
        </Container>
    );
}

Dashboard.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
};

Dashboard.defaultProps = {
    className: undefined,
    style: {},
};

export default Dashboard;
