import React from 'react';
import PropTypes from 'prop-types';
import { Container, HeaderContainer, ValueContainer } from './styled';

export default function ValueOutput(props) {
    const {
        className,
        style,
        header,
        value,
        size,
        variant,
    } = props;
    return (
        <Container className={className} style={style}>
            <HeaderContainer>
                {header}
            </HeaderContainer>
            <ValueContainer size={size} variant={variant}>
                {value}
            </ValueContainer>
        </Container>
    );
}

ValueOutput.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    header: PropTypes.node,
    value: PropTypes.node,
    variant: PropTypes.oneOf([
        'default',
        'error',
    ]),
    size: PropTypes.oneOf([
        'medium',
        'small',
        'x-small',
    ]),
};

ValueOutput.defaultProps = {
    className: undefined,
    style: {},
    header: 'Title',
    value: null,
    size: 'medium',
    variant: 'default',
};
