import React, { useState, useEffect } from 'react';
import { Spinner } from 'react-rainbow-components';
import { StyledImage } from './styled';

// eslint-disable-next-line react/prop-types
const Image = ({ imageRef }) => {
    const [imageUrl, setImageUrl] = useState(undefined);
    useEffect(() => {
        (async () => {
            // eslint-disable-next-line react/prop-types
            const url = await imageRef.getDownloadURL();
            setImageUrl(url);
        })();
    });
    if (imageUrl) {
        return <StyledImage src={imageUrl} alt="MMS" />;
    }
    return <Spinner />;
};

export default Image;
