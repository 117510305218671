import React from 'react';
import {
    Dataset,
} from 'react-rainbow-components';
import { useCollection } from '@rainbow-modules/firebase-hooks';
import RenderIf from 'react-rainbow-components/components/RenderIf';
import datalabels from 'chartjs-plugin-datalabels';
import colorschemes from 'chartjs-plugin-colorschemes';
import getCurrentUser from '../../../redux/services/firebase/get-current-user';
import empty from './empty.svg';
import {
    Content,
    ChartsCard,
    Title,
    BarChart,
    EmptyMessage,
    MessageText,
    EmptyImage,
    MessageTitle,
} from './styled';
import { getFirestorePath } from '../../../helpers';

const LAST_DAYS = 15;

function getDaysFromNow(days) {
    const today = new Date();
    today.setDate(today.getDate() - days + 1);
    return today;
}

function getFormattedDate(date) {
    const year = date.getFullYear();
    const dateMonth = date.getMonth() + 1;
    const month = dateMonth < 10 ? `0${dateMonth}` : dateMonth;
    const dateDay = date.getDate();
    const day = dateDay < 10 ? `0${dateDay}` : dateDay;
    return `${year}-${month}-${day}`;
}

function hasData(sent, delivered) {
    return sent.some((item) => item > 0) || delivered.some((item) => item > 0);
}

export default function Chart() {
    const date = getDaysFromNow(LAST_DAYS);
    const [history] = useCollection({
        path: getFirestorePath(`users/${getCurrentUser().uid}/history`),
        query: (ref) => ref.where('createdAt', '<=', new Date()).where('createdAt', '>=', date),
    });

    const sent = Array(LAST_DAYS).fill(0);
    const delivered = Array(LAST_DAYS).fill(0);
    const tmpLabels = Array.from(Array(LAST_DAYS), (_, index) => {
        const tmp = new Date(date);
        return getFormattedDate(new Date(tmp.setDate(tmp.getDate() + index)));
    });

    history.forEach((item) => {
        const index = tmpLabels.indexOf(item.id);
        if (index !== -1) {
            sent[index] = item.data.sent;
            delivered[index] = item.data.delivered;
        }
    });

    const labels = tmpLabels.map((label) => {
        const parts = label.split('-');
        return `${parts[1]}/${parts[2]}`;
    });

    const chartPlugins = [datalabels, colorschemes];
    const datalabelsConfig = {
        color: '#01b6f5',
        anchor: 'end',
        align: 'end',
        font: {
            weight: '500',
            size: 14,
        },
    };

    const showChart = hasData(sent, delivered);

    return (
        <ChartsCard>
            <Title>Messages on the last 15 days</Title>
            <Content>
                <RenderIf isTrue={showChart}>
                    <BarChart
                        labels={labels}
                        maintainAspectRatio={false}
                        type="bar"
                        legendPosition="top"
                        showLegend
                        disableAnimations
                        disableXAxisGridLines
                        disableYAxisGridLines
                        plugins={chartPlugins}
                        datalabels={datalabelsConfig}
                        colorschemes={{ scheme: '#01b6f5' }}
                    >
                        <Dataset
                            title="Sent"
                            values={sent.concat([0])}
                            backgroundColor="#01b6f5"
                            borderColor="#01b6f5"
                        />
                        <Dataset
                            title="Delivered"
                            values={delivered.concat([0])}
                            backgroundColor="#1de9b6"
                            borderColor="#1de9b6"
                            datalabels={{ color: '#1de9b6' }}
                        />
                    </BarChart>
                </RenderIf>
                <RenderIf isTrue={!showChart}>
                    <EmptyMessage>
                        <EmptyImage src={empty} alt="Empty Message" />
                        <MessageTitle>
                            Uh-oh!
                        </MessageTitle>
                        <MessageText>
                            We did not find any data to show...
                        </MessageText>
                    </EmptyMessage>
                </RenderIf>
            </Content>
        </ChartsCard>
    );
}
