import React from 'react';
import PropTypes from 'prop-types';
import {
    Breadcrumb, Spinner,
} from 'react-rainbow-components';
import { useParams } from 'react-router-dom';
import { useDoc } from '@rainbow-modules/firebase-hooks';
import { FormattedDate } from 'react-intl';
import TextOutput from '../../../components/text-output';
import LinkOutput from '../../../components/link-output';
import ValueOutput from '../../../components/value-output';
import getCurrentUser from '../../../redux/services/firebase/get-current-user';
import { navigateTo } from '../../../history';
// import getPercent from '../../../helpers/index';
// import ErrorCircle from '../../../components/icons/errorCircle';
import {
    Container,
    StyledBreadcrumbs,
    Content,
    CampaignDetailsCard,
    IconContainer,
    Campaign,
    CampaignName,
    CampaignDate,
    Cost,
    CampaignDetailsContent,
    CampaignInsightsCard,
    // InsightItem,
    // Progress,
    // InsightsIcon,
} from './styled';
import { getFirestorePath } from '../../../helpers';

export default function CampaignDetails({ className }) {
    const { id } = useParams();
    const [campaignDoc, isLoading] = useDoc({
        path: getFirestorePath(`users/${getCurrentUser().uid}/campaigns/${id}`),
    });

    if (isLoading || !campaignDoc) {
        return <Spinner />;
    }
    const campaign = campaignDoc.data;
    const {
        name,
        createdAt,
        body,
        cost,
        // received,
        // failed,
        group: {
            members,
            name: groupName = 'unknown',
            id: groupId,
        },
    } = campaign;
    const groupLink = groupId && `/app/groups/${groupId}`;
    // const receivedPercent = members && getPercent(received, members);
    // const errorsPercent = members && getPercent(failed, members);

    return (
        <Container className={className}>
            <StyledBreadcrumbs>
                <Breadcrumb label="Campaigns" onClick={() => navigateTo('/app/campaigns')} />
                <Breadcrumb label="Campaign Details" />
            </StyledBreadcrumbs>
            <Content>
                <CampaignDetailsCard
                    icon={(
                        <IconContainer>
                            <Campaign />
                        </IconContainer>
                    )}
                    title={(
                        <div>
                            <CampaignName>
                                {name}
                            </CampaignName>
                            <CampaignDate>
                                <FormattedDate
                                    value={createdAt.toDate()}
                                    year="numeric"
                                    month="long"
                                    day="numeric"
                                    hour="numeric"
                                    minute="numeric"
                                />
                            </CampaignDate>
                        </div>
                    )}
                    actions={
                        <Cost label="Cost" value={cost} size="small" />
                    }
                >
                    <CampaignDetailsContent>
                        <LinkOutput
                            label="To"
                            value={groupName}
                            className="rainbow-m-bottom_medium"
                            to={groupLink} />
                        <TextOutput
                            label="Message"
                            value={body} />
                    </CampaignDetailsContent>
                </CampaignDetailsCard>
                <CampaignInsightsCard>
                    <ValueOutput header="Group Members" value={members} />
                    {/* <InsightItem>
                        <ValueOutput
                            header={(
                                <span>
                                    <InsightsIcon />
                                    SMS Received
                                </span>
                            )}
                            value={received}
                            size="small" />
                        <Progress variant="success" value={receivedPercent} />
                    </InsightItem>
                    <InsightItem>
                        <ValueOutput
                            header={(
                                <span>
                                    <InsightsIcon as={ErrorCircle} />
                                    Errors
                                </span>
                            )}
                            value={failed}
                            size="small" />
                        <Progress variant="error" value={errorsPercent} />
                    </InsightItem> */}
                </CampaignInsightsCard>
            </Content>
            {/* <AnswersCard
                title={(<CampaignName>Answers</CampaignName>)}
                actions={(
                    <AnswersTotal
                        header="Total"
                        value="0"
                        size="x-small" />
                )}>
                <Table keyField="id">
                    <Column header="Phone Number" field="phoneNumber" />
                    <Column header="Name" field="name" />
                    <Column header="Date" field="date" />
                    <Column header="Message" field="message" />
                </Table>
            </AnswersCard> */}
        </Container>
    );
}

CampaignDetails.propTypes = {
    className: PropTypes.string,
};

CampaignDetails.defaultProps = {
    className: undefined,
};
