export function getPercent(percentFor, percentOf) {
    return Math.floor((percentFor / percentOf) * 100) || 0;
}

export const getNormalizedBody = (body, stop, stopText) => {
    const rawText = body || '';
    const trimedText = rawText.trim();
    if (stop) {
        return `${trimedText}${stopText}`;
    }
    return trimedText;
};

export const getFunctionName = (baseName) => {
    const prefix = process.env.REACT_APP_MODULE_PREFIX;
    if (prefix) {
        return `${prefix}-${baseName}`;
    }
    return baseName;
};

export const getFirestorePath = (path) => {
    const prefix = process.env.REACT_APP_MODULE_PREFIX;
    if (prefix) {
        return `apps/${prefix}/${path}`;
    }
    return path;
};
