import { useEffect, useState } from 'react';
import { getCurrentUser } from '../redux/services/firebase';

const useCurrentUser = () => {
    const [user, setUser] = useState({});
    useEffect(() => {
        const firebaseUser = getCurrentUser();
        setUser(firebaseUser);
    }, []);
    return user;
};

export default useCurrentUser;
