/* eslint-disable no-param-reassign */
import { fromJS } from 'immutable';
import {
    USER_AUTHENTICATED,
    USER_LOGOUT_DONE,
    AUTH_SUCCESS,
    AUTH_START,
    UPDATE_USER_DATA,
} from '../../actions/authentication';
import { SHOW_ERROR_MESSAGE } from '../../actions/app/show-error-message';

import * as userGetters from './user-getters';

const initialState = fromJS({
    authenticationResolved: false,
    isLoading: false,
    user: null,
});

function authenticateUser(state, user) {
    return state.set('user', {
        ...user,
        ...userGetters,
    });
}

function authStart(state) {
    return state.set('isLoading', true);
}

function updateUserData(state, data) {
    const { user } = state.toJS();
    return state.set('user', Object.assign(user, data));
}

function stopLoading(state) {
    return state.set('isLoading', false);
}

export default function (state = initialState, action) {
    switch (action.type) {
        case USER_AUTHENTICATED:
            return authenticateUser(state, action.user);

        case AUTH_START:
            return authStart(state);

        case SHOW_ERROR_MESSAGE:
            return stopLoading(state);

        case AUTH_SUCCESS:
            return stopLoading(state);

        case USER_LOGOUT_DONE:
            return initialState;

        case UPDATE_USER_DATA:
            return updateUserData(state, action.data);

        default:
            return state;
    }
}
