import React from 'react';
import PropTypes from 'prop-types';
import { MembersResumeContainer } from './styled';
import InfoTile from './info-tile';

const MembersResume = (props) => {
    const {
        total, unsubscribes,
    } = props;
    return (

        <MembersResumeContainer>
            <InfoTile
                className="rainbow-m-horizontal_xx-small"
                label="Total Members"
                value={total || 0}
                size="small"
            />

            <InfoTile
                className="rainbow-m-horizontal_xx-small"
                label="Unsubscribes"
                value={unsubscribes || 0}
                size="small"
                variant="error"
            />
        </MembersResumeContainer>
    );
};

MembersResume.propTypes = {
    total: PropTypes.number,
    unsubscribes: PropTypes.number,
};

MembersResume.defaultProps = {
    total: 0,
    unsubscribes: 0,
};

export default MembersResume;
