import React from 'react';
import useCurrentUser from '../../../hooks/useCurrentUser';
import Form from './form';

export default function Profile() {
    const user = useCurrentUser();
    const initialValues = {
        displayName: user.displayName,
        email: user.email,
    };
    return (
        <Form initialValues={initialValues} user={user} />
    );
}
