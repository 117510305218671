import styled from 'styled-components';
import Price from '../../price';

export const Title = styled.h1`
    font-size: 32px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 300;
`;

export const Credit = styled(Price)`
    align-items: flex-end;
`;
