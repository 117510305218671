/* eslint-disable react/no-unused-prop-types */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Textarea } from 'react-rainbow-components';
import useReduxForm from 'react-rainbow-components/libs/hooks/useReduxForm';
import useUniqueIdentifier from 'react-rainbow-components/libs/hooks/useUniqueIdentifier';
import useLabelId from 'react-rainbow-components/libs/hooks/useLabelId';
import useErrorMessageId from 'react-rainbow-components/libs/hooks/useErrorMessageId';
import FooterTextarea from './footerTextarea';
import FileChip from './fileChip';

function getMaxTextCount(isMms, messagesCount) {
    if (isMms) {
        return 1600;
    }
    if (messagesCount > 0) {
        return 160 * messagesCount;
    }
    return 160;
}

function getMessagesCount(isMms, length) {
    if (isMms) {
        return length && 1;
    }
    if (length >= 800) {
        return 5;
    }
    return Math.ceil(length / 160);
}

export default function MessageTextarea(props) {
    const {
        value,
        placeholder,
        required,
        error,
        onChange,
        rows,
        name,
        bottomHelpText,
        disabled,
        onSelectType,
        type,
        message,
        onSelectImage,
    } = useReduxForm(props);
    const textareaId = useUniqueIdentifier('textarea');
    const inlineTextLabelId = useLabelId('inline-text-label');
    const errorMessageId = useErrorMessageId('error-message');
    const textInputRef = useRef(null);
    const [imageRef, setImageRef] = useState(undefined);

    const isMms = type === 'mms';

    const { length } = message;

    const messagesCount = getMessagesCount(isMms, length);
    const maxTextCount = getMaxTextCount(isMms, messagesCount);
    const maxMessageCount = isMms ? 1 : 5;
    const maxLength = isMms ? 1600 : 800;
    const handleSelectImage = (ref) => {
        setImageRef(ref);
        onSelectImage(ref);
    };

    return (
        <Textarea
            header={(
                <FileChip
                    imageRef={imageRef}
                    onDelete={() => handleSelectImage(undefined)} />
            )}
            error={error}
            id={textareaId}
            name={name}
            placeholder={placeholder}
            disabled={disabled}
            required={required}
            onChange={onChange}
            rows={rows}
            value={value}
            aria-labelledby={inlineTextLabelId}
            aria-describedby={errorMessageId}
            maxLength={maxLength}
            ref={textInputRef}
            label="Message"
            bottomHelpText={bottomHelpText}
            grow
            footer={(
                <FooterTextarea
                    onSelectType={onSelectType}
                    onSelectImage={handleSelectImage}
                    length={length}
                    maxTextCount={maxTextCount}
                    messagesCount={messagesCount}
                    maxMessageCount={maxMessageCount} />
            )}
        />
    );
}

MessageTextarea.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    label: PropTypes.node,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    error: PropTypes.node,
    onChange: PropTypes.func,
    rows: PropTypes.number,
    name: PropTypes.string,
    bottomHelpText: PropTypes.node,
    disabled: PropTypes.bool,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onSelectType: PropTypes.func,
    type: PropTypes.oneOf(['sms', 'mms']),
    grow: PropTypes.bool,
    message: PropTypes.string,
};

MessageTextarea.defaultProps = {
    className: undefined,
    style: undefined,
    label: undefined,
    value: '',
    placeholder: '',
    required: false,
    error: undefined,
    onChange: () => {},
    rows: undefined,
    name: undefined,
    bottomHelpText: undefined,
    disabled: false,
    onFocus: () => {},
    onBlur: () => {},
    onSelectType: () => {},
    type: 'sms',
    grow: false,
    message: '',
};
