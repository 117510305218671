import React from 'react';
import PropTypes from 'prop-types';

export default function PlusCircle(props) {
    const { className, style } = props;
    return (
        <svg width="20px" height="20px" viewBox="0 0 20 20" className={className} style={style}>
            <g id="Campaigns" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path d="M10,0 C15.5142212,0 20,4.48577883 20,10 C20,15.5142212 15.5142212,20 10,20 C4.48577883,20 0,15.5142212 0,10 C0,4.48577883 4.48577883,0 10,0 Z M10.0919478,4.50099903 L10.0128368,4.50809279 L9.91166724,4.53327293 C9.61869236,4.62781619 9.42408531,4.88872564 9.42408531,5.19019001 L9.424,9.426 L5.19016191,9.4266164 L5.08873755,9.43470871 C4.74021377,9.49694634 4.5,9.78183193 4.5,10.1168064 L4.5080897,10.2182374 L4.5332644,10.3194113 C4.62779045,10.6123971 4.88867739,10.8069964 5.19019001,10.8069964 L9.424,10.805 L9.42408531,15.1098138 L9.43217762,15.2112625 C9.49441525,15.5597862 9.77930084,15.8 10.1142753,15.8 L10.2157063,15.7919103 L10.3168802,15.7667356 C10.609866,15.6722096 10.8044653,15.4113226 10.8044653,15.10981 L10.803,10.805 L15.1097652,10.8069964 L15.2112625,10.7989041 C15.5597862,10.7366665 15.8,10.4517809 15.8,10.1168064 L15.7919072,10.0153679 L15.7667271,9.91419833 C15.6721838,9.62122345 15.4112744,9.4266164 15.10981,9.4266164 L10.803,9.426 L10.8044653,5.19021122 L10.796373,5.08873755 C10.7403592,4.77506615 10.5040004,4.54912585 10.2130924,4.50707451 L10.0919478,4.50099903 Z" id="Combined-Shape" fill="#03BBFB" fillRule="nonzero" />
            </g>
        </svg>
    );
}

PlusCircle.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};

PlusCircle.defaultProps = {
    style: {},
    className: undefined,
};
