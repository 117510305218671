import styled from 'styled-components';
import {
    Table,
    Input,
    Breadcrumbs,
    ButtonIcon,
} from 'react-rainbow-components';
import ArrowRight from '../../../../components/icons/arrowRight';
import Magnifying from '../../../../components/icons/magnifying';

export const Container = styled.section`
    display: flex;
    height: 100%;
    overflow: auto;
    flex-direction: column;
`;

export const StyledBreadcrumbs = styled(Breadcrumbs)`
    margin: 0 0 16px 36px;
`;

export const MembersCard = styled.div`
    margin: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: auto;
`;

export const MembersTable = styled(Table)`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: auto;
    margin-bottom: 20px;
`;

export const Name = styled.h3.attrs((props) => props.theme.rainbow.palette)`
    font-size: 1.4rem;
    line-height: 1.2;
    color: ${(props) => props.theme.rainbow.palette.text.label};
`;

export const SearchInput = styled(Input)`
    width: 320px;

    input {
        border: 1px solid transparent;
        box-shadow: ${(props) => props.theme.rainbow.shadows.shadow_10};
    }
`;

export const MembersResumeContainer = styled.div`
    display: flex;
    margin: 0 32px;
`;

export const IconContainer = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 100px;
    background-color: ${(props) => props.theme.rainbow.palette.background.disabled};
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Actions = styled.div`
    display: flex;
`;

export const SearchContainer = styled.div`
    display: flex;
    position: relative;
`;

export const StyledButton = styled(ButtonIcon)`
    right: 2px;
    top: 2px;
    position: absolute;
    height: 36px;
    width: 36px;
    background: #f0f4fa;
    border: 1px solid transparent;
`;

export const Icon = styled(Magnifying)`
    width: 24px;
    height: 24px;
    color: ${(props) => props.theme.rainbow.palette.brand.main};
`;

export const Arrow = styled(ArrowRight)`
    color: transparent;
`;
