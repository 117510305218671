import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import './styles.css';

export const COPYRIGHT = <FormattedMessage id="copyright" defaultMessage="All rights reserved." />;

function getYear() {
    return new Date().getFullYear();
}

export default function PublicFooter(props) {
    const {
        className,
        style,
    } = props;

    const getContainerClassNames = () => classnames('package-manager-public-footer', className);

    return (
        <p className={getContainerClassNames()} style={style}>
            {`© ${getYear()} Nexxtway Corp.`}
            {' '}
            {COPYRIGHT}
        </p>
    );
}

PublicFooter.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
};

PublicFooter.defaultProps = {
    className: undefined,
    style: undefined,
};
