import { useCollection } from '@rainbow-modules/firebase-hooks';
import useCurrentUser from './useCurrentUser';
import { getFirestorePath } from '../helpers';

const useDefaultPhoneNumber = () => {
    const { uid } = useCurrentUser();
    const [numbers, isLoading] = useCollection({
        path: getFirestorePath(`users/${uid}/numbers`),
    });

    const hasDefaultNumber = numbers[0] && numbers[0].data && numbers[0].data.friendlyName;

    if (isLoading || !hasDefaultNumber) {
        return '';
    }

    return numbers[0].data.friendlyName;
};

export default useDefaultPhoneNumber;
