import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Column,
    MenuItem,
    Drawer,
} from 'react-rainbow-components';
import { useCollection } from '@rainbow-modules/firebase-hooks';
import getCurrentUser from '../../../redux/services/firebase/get-current-user';
import NewGroupForm from './newGroupForm';
import {
    Container,
    GroupCard,
    GroupTable,
    Header,
    Title,
} from './styled';
import FormattedDateCol from '../../../components/formatted-date-col';
import removeGroup from './removeGroup';
import useCurrentUser from '../../../hooks/useCurrentUser';
import GroupLink from './groupLink';
import { getFirestorePath } from '../../../helpers';

function Groups({ location }) {
    const { state } = location;
    const isDrawerOpen = state && state.isDrawerOpen;
    const [isOpen, setIsOpen] = useState(isDrawerOpen);
    const [groups, isLoading] = useCollection({
        path: getFirestorePath(`users/${getCurrentUser().uid}/groups`),
        query: (ref) => ref.where('visible', '==', true),
    });
    const { uid } = useCurrentUser();
    const remove = async (event, { id: groupId }) => {
        await removeGroup(uid, groupId);
    };
    return (
        <Container>
            <GroupCard>
                <Header>
                    <Title>Your Groups</Title>
                    <Button
                        variant="brand"
                        label="Create Group"
                        onClick={() => setIsOpen(true)} />
                </Header>
                <GroupTable keyField="id" data={groups} isLoading={isLoading}>
                    <Column header="Group Name" field="data.name" component={GroupLink} />
                    <Column header="Contacts" field="data.members" />
                    <Column header="Created at" field="data.createdAt" component={FormattedDateCol} />
                    <Column type="action">
                        <MenuItem label="Delete" onClick={remove} />
                    </Column>
                </GroupTable>
            </GroupCard>
            <Drawer
                header="Create Group"
                isOpen={isOpen}
                slideFrom="right"
                onRequestClose={() => setIsOpen(false)}>
                <NewGroupForm onRequestClose={() => setIsOpen(false)} />
            </Drawer>
        </Container>
    );
}

Groups.propTypes = {
    location: PropTypes.object.isRequired,
};

export default Groups;
