import React from 'react';
import PropTypes from 'prop-types';

export default function Plus(props) {
    const { className, style } = props;
    return (
        <svg width="256px" height="256px" viewBox="0 0 256 256" className={className} style={style}>
            <g id="Billing" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="plus" fill="currentColor" fillRule="nonzero">
                    <path d="M240,144 L16,144 C7.167969,144 0,136.832031 0,128 C0,119.167969 7.167969,112 16,112 L240,112 C248.832031,112 256,119.167969 256,128 C256,136.832031 248.832031,144 240,144 Z" id="Path" />
                    <path d="M128,256 C119.167969,256 112,248.832031 112,240 L112,16 C112,7.167969 119.167969,0 128,0 C136.832031,0 144,7.167969 144,16 L144,240 C144,248.832031 136.832031,256 128,256 Z" id="Path" />
                </g>
            </g>
        </svg>
    );
}

Plus.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};

Plus.defaultProps = {
    style: {},
    className: undefined,
};
