import React from 'react';
import PropTypes from 'prop-types';
import Visa from './icons/visa';
import MasterCard from './icons/mastercard';
import Amex from './icons/amex';
import Discover from './icons/discover';
import Jcb from './icons/jcb';
import Unknown from './icons/unknown';
import {
    Container, Icon,
} from './styled';

const iconMap = {
    visa: Visa,
    mastercard: MasterCard,
    amex: Amex,
    discover: Discover,
    jcb: Jcb,
};

export default function CreditCardIcon(props) {
    const {
        className,
        style,
        value,
        id,
    } = props;

    const icon = iconMap[value] || Unknown;

    return (
        <Container
            className={className}
            style={style}
            id={id}
            value={value}
        >
            <Icon value={value} as={icon} />
        </Container>
    );
}

CreditCardIcon.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    style: PropTypes.object,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

CreditCardIcon.defaultProps = {
    className: undefined,
    id: undefined,
    style: undefined,
    value: undefined,
};
