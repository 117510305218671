import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { EvertransitAvatar } from '@nexxtway/react-evertransit';
import { useDispatch } from 'react-redux';
import {
    AvatarMenu,
    Avatar,
    MenuDivider,
    MenuItem,
} from 'react-rainbow-components';
import { useHistory } from 'react-router-dom';
import { logoutUser } from '../../redux/actions/authentication';
import useCurrentUser from '../../hooks/useCurrentUser';
import PowerIcon from '../icons/power';
import PersonIcon from '../icons/person';
import './styles.css';
import useCurrentCredit from '../../hooks/useCurrentCredit';
import AddCreditButton from '../add-credit-button';
import RenderIfStandaloneMode from '../render-if-standalone-mode';
import RenderIfEtMode from '../render-if-et-mode';
import { Title, Credit } from './styled';

const titlesMap = {
    '/app/campaigns': 'Campaigns',
    '/app/dashboard': 'Dashboard',
    '/app/groups': 'Groups',
    '/app/campaigns/:id': 'Campaign Details',
    '/app/groups/:id': 'Group Details',
    '/app/profile': 'Profile',
};

const getBaseRoot = (pathname) => {
    const paths = pathname.split('/');
    const basePath = paths.slice(0, 3).join('/');
    const baseRoot = paths.length === 4 ? `${basePath}/:id` : basePath;
    return baseRoot;
};

export default function TopBar(props) {
    const {
        className,
        style,
    } = props;
    const { location, push: navigate } = useHistory();
    const dispatch = useDispatch();
    const user = useCurrentUser();

    const title = titlesMap[getBaseRoot(location.pathname)];

    const getContainerClassNames = () => classnames('rainbow-auth-firebase-auth_top-bar', className);
    const credit = useCurrentCredit();
    return (
        <section className={getContainerClassNames()} style={style}>
            <Title>{title}</Title>
            <div className="rainbow-auth-firebase_top-bar-content">
                <AddCreditButton className="rainbow-m-right_medium" />
                <Credit label="Credit" value={credit.toFixed(2)} />
                <RenderIfStandaloneMode>
                    <AvatarMenu
                        src={user.photoURL}
                        assistiveText={user.displayName}
                        menuAlignment="right"
                        menuSize="small"
                        avatarSize="large"
                        className="rainbow-auth-firebase_avatar-menu"
                        title={user.displayName}
                        icon={<PersonIcon className="rainbow-auth-firebase_avatar-menu_user-icon" />}>
                        <li className="rainbow-auth-firebase_avatar-menu_user">
                            <Avatar
                                src={user.photoURL}
                                assistiveText={user.displayName}
                                title={user.displayName}
                                icon={<PersonIcon className="rainbow-auth-firebase_avatar-menu_user-icon" />}
                                size="medium" />
                            <div className="rainbow-auth-firebase_avatar-menu_user-detail">
                                <p className="rainbow-auth-firebase_avatar-menu_user-name">{user.displayName}</p>
                                <p className="rainbow-auth-firebase_avatar-menu_user-email">{user.email}</p>
                            </div>
                        </li>
                        <MenuDivider variant="space" />
                        <MenuItem
                            onClick={() => navigate('/app/profile')}
                            label="Profile Info"
                            icon={<PersonIcon className="rainbow-auth-firebase_avatar-menu_menu-icon" />}
                            iconPosition="left" />
                        <MenuItem
                            label="Logout"
                            onClick={() => dispatch(logoutUser())}
                            icon={<PowerIcon className="rainbow-auth-firebase_avatar-menu_menu-icon" />}
                            iconPosition="left" />
                    </AvatarMenu>
                </RenderIfStandaloneMode>
                <RenderIfEtMode>
                    <EvertransitAvatar className="rainbow-auth-firebase_avatar-menu" />
                </RenderIfEtMode>
            </div>
        </section>
    );
}

TopBar.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
};

TopBar.defaultProps = {
    className: undefined,
    style: {},
};
