import styled from 'styled-components';
import { Breadcrumbs, Card, ProgressCircular } from 'react-rainbow-components';
import CampaignIcon from '../../../../components/icons/campaign';
import Price from '../../../../components/price';
import ValueOutput from '../../../../components/value-output';
import CheckmarkCircle from '../../../../components/icons/checkmarkCircle';

export const Container = styled.section`
    display: flex;
    height: 100%;
    overflow: auto;
    flex-direction: column;
`;

export const StyledBreadcrumbs = styled(Breadcrumbs)`
    margin: 0 0 16px 36px;
`;

export const Content = styled.div`
    display: flex;
    margin: 0 32px 0 36px;
`;

export const CampaignDetailsCard = styled(Card)`
    flex-grow: 1;
    height: fit-content;
`;

export const IconContainer = styled.div`
    width: 44px;
    height: 44px;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.theme.rainbow.palette.background.highlight};
`;

export const Campaign = styled(CampaignIcon)`
    width: 28px;
    height: 28px;
`;

export const CampaignName = styled.h1`
    font-size: 1.4rem;
    line-height: 1.25;
    color: ${(props) => props.theme.rainbow.palette.text.label};
`;

export const CampaignDate = styled.h2`
    font-size: 14px;
    line-height: 1.5;
    color: ${(props) => props.theme.rainbow.palette.text.header};
`;

export const Cost = styled(Price)`
    align-items: flex-end;
`;

export const CampaignDetailsContent = styled.div`
    width: 100%;
    padding: 20px 32px;
    border-top: 1px solid ${(props) => props.theme.rainbow.palette.border.divider};
`;

export const CampaignInsightsCard = styled(Card)`
    min-width: 300px;
    margin-left: 8px;
    padding: 16px 20px 12px 20px;
    flex-shrink: 1;
    height: fit-content;
`;

export const InsightItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 16px 0;
    border-bottom: 1px solid ${(props) => props.theme.rainbow.palette.border.divider};
`;

export const InsightsIcon = styled(CheckmarkCircle)`
    width: 12px;
    height: 12px;
    margin-right: 6px;
    margin-bottom: 2px;
`;

export const Progress = styled(ProgressCircular)`
    width: 64px;
    height: 64px;

    > h1 {
        font-size: 18px;
        font-weight: 600;
    }
`;

export const AnswersCard = styled(Card)`
    margin: 0 32px 0 36px;
    margin-top: 8px;
    height: 100%;
`;

export const AnswersTotal = styled(ValueOutput)`
    align-items: flex-end;
`;
