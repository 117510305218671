import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    VisualPicker,
    Button,
} from 'react-rainbow-components';
import { showAppMessage } from '@rainbow-modules/app';
import { useDispatch } from 'react-redux';
import { showSpinner, hideSpinner } from '../../../global-spinner';
import Price from '../../../price';
import {
    Container,
    Option,
    // Summary,
    // Divider,
    // TotalCost,
    Content,
    Footer,
    Title,
    CardIcon,
} from '../../styled';
import goCheckout from '../../services/goCheckout';
import useCustomerCards from '../../hooks/useCustomerCards';
import SavedCards from '../savedCards';
import RadioCardPicker from '../radioCardPicker';
import processPayment from '../../services/processPayment';

const formatCurrency = new Intl.NumberFormat('en', { style: 'currency', currency: 'usd' }).format;

function AddCreditForm(props) {
    const {
        className,
        style,
        onRequestClose,
    } = props;
    const [amount, setAmount] = useState('100');
    const [paymentOpt, setPaymentOption] = useState();
    const dispatch = useDispatch();
    const onSubmit = async () => {
        if (paymentOpt === 'newCard') {
            dispatch(showSpinner({
                message: 'Redirecting to Stripe Checkout',
            }));
            goCheckout({
                amount: +amount * 100,
            });
        } else if (paymentOpt) {
            dispatch(showSpinner({
                message: 'Processing Your Payment',
            }));
            const res = await processPayment({
                amount: +amount,
                paymentMethod: paymentOpt,
            });
            onRequestClose();
            if (res.error) {
                showAppMessage({
                    message: `Oh oh!, ${res.error.message}`,
                    variant: 'error',
                });
            } else {
                showAppMessage({
                    message: `Your acccount has been credited with ${formatCurrency(+amount)}`,
                    variant: 'success',
                });
            }
            dispatch(hideSpinner());
        }
    };
    const [cards, isLoadingCards] = useCustomerCards();
    const onChange = (event) => setPaymentOption(event.target.id);
    return (
        <Container className={className} style={style}>
            <Content>
                <Title>Select Price</Title>
                <VisualPicker
                    value={amount}
                    onChange={(newAmount) => setAmount(newAmount)}
                    className="rainbow-m-top_medium"
                    size="small">
                    <Option name="50">
                        <Price value="50" size="small" />
                    </Option>
                    <Option name="100">
                        <Price value="100" size="small" />
                    </Option>
                    <Option name="200">
                        <Price value="200" size="small" />
                    </Option>
                </VisualPicker>

                <Title className="rainbow-m-top_large">Pay with</Title>
                <SavedCards
                    cards={cards}
                    value={paymentOpt}
                    isLoading={isLoadingCards}
                    onChange={onChange} />
                <RadioCardPicker
                    id="newCard"
                    onChange={onChange}
                    isChecked={paymentOpt === 'newCard'}
                    className="rainbow-m-bottom_xx-small rainbow-m-vertical_xx-small">
                    <div>
                        <CardIcon />
                        New Card
                    </div>
                </RadioCardPicker>
            </Content>
            <Footer>
                <Button
                    variant="brand"
                    label="Pay"
                    onClick={onSubmit}
                    disabled={!paymentOpt}
                    className="rainbow-m-horizontal_small rainbow-p-horizontal_x-large"
                />
            </Footer>
        </Container>
    );
}

AddCreditForm.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    onRequestClose: PropTypes.func,
};

AddCreditForm.defaultProps = {
    className: undefined,
    style: {},
    onRequestClose: () => {},
};

export default AddCreditForm;
