import styled from 'styled-components';
import { VisualPickerOption, Drawer } from 'react-rainbow-components';
import Plus from '../../icons/plus';
import AddCard from '../../icons/addCard';
import Price from '../../price';

export const AddCreditDrawer = styled(Drawer)`
    width: 400px;
    min-width: 400px;
`;

export const Container = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
`;

export const Content = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 16px;
`;

export const Title = styled.h1`
    font-size: 16px;
    font-weight: 300;
    margin-top: 6px;
    color: ${(props) => props.theme.rainbow.palette.brand.main};
    text-transform: uppercase;
`;

export const Label = styled.h1`
    font-size: 36px;
    font-weight: 300;
    margin-top: 6px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
`;

export const Option = styled(VisualPickerOption)`
    width: 100px;

    > label {
        width: 100px;
        height: 100px;
    }
`;

export const TotalCost = styled(Price)`
    align-items: center;
    justify-content: center;
`;

export const Footer = styled.footer`
    width: 100%;
    padding-top: 20px;
    display: flex;
    justify-content: flex-end;
`;

export const Summary = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    padding-bottom: 24px;
`;

export const Divider = styled.div`
    height: 80%;
    width: 1px;
    background-color: #dcdcdc;
    margin-top: 4px;
`;

export const PlusIcon = styled(Plus)`
    height: 16px;
    width: 16px;
    margin-right: 8px;
`;

export const CardIcon = styled(AddCard)`
    width: 45px;
    height: 32px;
    margin-right: 16px;
`;

export const CardTitle = styled.h2`
    font-size: 16px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
`;
