import { useCollection } from '@rainbow-modules/firebase-hooks';
import useCurrentUser from './useCurrentUser';
import { getFirestorePath } from '../helpers';

const query = (ref) => ref.where('visible', '==', true);

const useGroups = () => {
    const { uid } = useCurrentUser();
    return useCollection({
        path: getFirestorePath(`users/${uid}/groups`),
        query,
    });
};

export default useGroups;
