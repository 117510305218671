import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import Link from '../../../components/link';

const GroupLink = ({ value, row: { id } }) => {
    const { pathname } = useLocation();
    const groupDetails = `${pathname}/${id}`;
    return <Link to={groupDetails} value={value} />;
};
GroupLink.propTypes = {
    value: PropTypes.string,
    row: PropTypes.object,
};
GroupLink.defaultProps = {
    value: 'Link',
    row: {
        id: '0',
    },
};

export default GroupLink;
