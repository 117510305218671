import React from 'react';
import PropTypes from 'prop-types';
import { RenderIf } from 'react-rainbow-components';
import {
    PhoneBorder,
    ScreenContainer,
    SensorsContainer,
    PhoneNumberContainer,
    MessagesContainer,
    Message,
} from './styled';
import PhoneSensors from '../icons/phoneSensors';
import Image from './image';

const Messages = ({ text, messageLength }) => {
    const messagesCount = Math.ceil(text.length / messageLength);
    return (
        Array(messagesCount).fill()
            .map((_, index) => {
                const key = `message-${index}`;
                const messageText = text.substr(index * messageLength, messageLength);
                return (
                    <Message key={key}>
                        {messageText}
                    </Message>
                );
            })
    );
};

const MessagePhone = (props) => {
    const {
        className,
        style,
        text,
        type,
        phoneNumber,
        imageRef,
    } = props;
    const messageLength = type === 'sms' ? 160 : 1600;
    return (
        <PhoneBorder className={className} style={style}>
            <ScreenContainer>
                <SensorsContainer>
                    <PhoneSensors />
                </SensorsContainer>
                <PhoneNumberContainer>
                    {phoneNumber}
                </PhoneNumberContainer>
                <MessagesContainer>
                    <RenderIf isTrue={imageRef}>
                        <Image imageRef={imageRef} />
                    </RenderIf>
                    <Messages text={text} messageLength={messageLength} />
                </MessagesContainer>
            </ScreenContainer>
        </PhoneBorder>
    );
};

MessagePhone.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    text: PropTypes.string,
    type: PropTypes.oneOf(['sms', 'mms']),
    phoneNumber: PropTypes.string,
    imageRef: PropTypes.object,
};

MessagePhone.defaultProps = {
    className: undefined,
    style: {},
    text: '',
    type: 'sms',
    phoneNumber: undefined,
    imageRef: undefined,
};

export default MessagePhone;
