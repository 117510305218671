import React from 'react';
import PropTypes from 'prop-types';

const MasterCard = (props) => {
    const { className } = props;
    return (
        <svg className={className} width="45px" height="32px" viewBox="0 0 45 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <g id="mensajes" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="master-card" fillRule="nonzero">
                    <path d="M42.1518125,0.125 C42.84839,0.125 43.4788957,0.411722889 43.9353205,0.875017864 C44.3924376,1.3390155 44.675,1.98006831 44.675,2.68804821 L44.675,2.68804821 L44.675,28.671863 C44.675,29.3798713 44.3924284,30.0209341 43.9353174,30.4849409 C43.4788802,30.9482637 42.8483631,31.235 42.1518125,31.235 L42.1518125,31.235 L2.6481875,31.235 C1.95163687,31.235 1.32111982,30.9482637 0.864682618,30.4849409 C0.407571648,30.0209341 0.125,29.3798713 0.125,28.671863 L0.125,28.671863 L0.125,2.68804821 C0.125,1.9800474 0.407566008,1.33901185 0.864669294,0.87502821 C1.32110791,0.411719251 1.95163058,0.125 2.6481875,0.125 L2.6481875,0.125 Z" id="Shape" stroke="#E3E5ED" strokeWidth="0.25" fill="#FFFFFF" />
                    <g id="mastercard-(1)" transform="translate(8.000000, 7.000000)">
                        <path d="M17.8335,8.98972826 C17.8335,13.916837 13.8420978,17.9091196 8.91675,17.9091196 C3.99140217,17.9091196 0,13.9159565 0,8.98972826 C0,4.0635 3.99238043,0.0703369565 8.91675,0.0703369565 C13.8411196,0.0703369565 17.8335,4.0635 17.8335,8.98972826 Z" id="Path" fill="#EE0005" />
                        <path d="M19.6254783,0.0703369565 C17.6257174,0.0703369565 15.7847283,0.736630435 14.2969891,1.84891304 L14.3041304,1.84979348 C14.5967283,2.13251087 14.9195543,2.33148913 15.1684239,2.65695652 L13.3131522,2.68640217 C13.022413,2.97988043 12.7575,3.29918478 12.50775,3.63101087 L15.7793478,3.63101087 C16.0282174,3.92977174 16.2583043,4.18940217 16.4670652,4.51936957 L11.9146304,4.51936957 C11.747837,4.80658696 11.5935652,5.10182609 11.4588587,5.4086087 L16.9853478,5.4086087 C17.129837,5.71451087 17.259163,5.94557609 17.3689239,6.26938043 L11.1315326,6.26938043 C11.0325326,6.5771413 10.9478152,6.89194565 10.8835435,7.21301087 L17.6302174,7.21301087 C17.69625,7.52165217 17.7470217,7.80701087 17.7818478,8.09784783 L10.7497174,8.09784783 C10.7202717,8.39132609 10.7051087,8.68920652 10.7051087,8.98982609 L17.8326196,8.98982609 C17.8326196,9.3056087 17.8103152,9.5981087 17.7782283,9.88180435 L10.7497174,9.88180435 C10.7800435,10.1841848 10.8246522,10.4811848 10.8835435,10.7737826 L17.6194565,10.7737826 C17.5499022,11.0627609 17.469587,11.3535 17.3688261,11.655 L11.1109891,11.655 C11.2054891,11.9600217 11.3206304,12.2561413 11.446337,12.5451196 L16.9853478,12.5451196 C16.8319565,12.8698043 16.6580217,13.129337 16.4662826,13.4334783 L11.8986848,13.4334783 C12.0788804,13.7456413 12.2777609,14.0435217 12.4935652,14.3289783 L15.7794457,14.3779891 C15.4993696,14.7142174 15.1399565,14.9167174 14.8125326,15.2110761 C14.830337,15.2253587 14.2889674,15.2093152 13.2230543,15.1923913 C14.8446196,16.8657065 17.1110543,17.9092174 19.6253804,17.9092174 C24.5516087,17.9092174 28.5447717,13.9159565 28.5447717,8.98982609 C28.5447717,4.06369565 24.5524891,0.0703369565 19.6254783,0.0703369565 Z" id="Path" fill="#F9A000" />
                    </g>
                </g>
            </g>
        </svg>
    );
};

MasterCard.propTypes = {
    className: PropTypes.string,
};
MasterCard.defaultProps = {
    className: undefined,
};

export default MasterCard;
