import React from 'react';
import PropTypes from 'prop-types';

const PhoneSensors = (props) => {
    const { className } = props;
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="171" height="21" viewBox="0 0 171 21">
            <defs>
                <linearGradient id="linear-gradient" x1="0.012" x2="0.987" gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor="#211f21" />
                    <stop offset="1" stopColor="#211f21" />
                </linearGradient>
            </defs>
            <g id="Group_1411" data-name="Group 1411" transform="translate(-76.95 -10)">
                <path id="Rectangle_468" data-name="Rectangle 468" d="M0,0H171a0,0,0,0,1,0,0V5a16,16,0,0,1-16,16H16A16,16,0,0,1,0,5V0A0,0,0,0,1,0,0Z" transform="translate(76.95 10)" fill="url(#linear-gradient)" />
            </g>
        </svg>
    );
};

PhoneSensors.propTypes = {
    className: PropTypes.string,
};
PhoneSensors.defaultProps = {
    className: undefined,
};

export default PhoneSensors;
