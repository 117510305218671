import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Sidebar,
    SidebarItem,
} from 'react-rainbow-components';
import { useHistory } from 'react-router-dom';
import {
    StyledSection, SidebarIcon, LogoIcon, DashboardIcon,
} from './styled';
import GroupsIcon from '../icons/groups';

function AppSidebar(props) {
    const {
        className,
        style,
    } = props;
    const { location, push: navigate } = useHistory();
    const currentPathname = location.pathname;
    const [selectedItem, setSelectedItem] = useState(currentPathname);

    useEffect(() => {
        setSelectedItem(currentPathname);
    }, [currentPathname]);

    const navigateToDashboardPage = () => {
        navigate('/app/dashboard');
    };

    const navigateToCampaignsPage = () => {
        navigate('/app/campaigns');
    };

    const navigateToGroupsPage = () => {
        navigate('/app/groups');
    };

    return (
        <StyledSection className={className} style={style}>
            <LogoIcon />
            <Sidebar selectedItem={selectedItem}>
                <SidebarItem onClick={navigateToDashboardPage} icon={<DashboardIcon />} name="/app/dashboard" label="Dashboard" />
                <SidebarItem onClick={navigateToCampaignsPage} icon={<SidebarIcon />} name="/app/campaigns" label="Campaigns" />
                <SidebarItem onClick={navigateToGroupsPage} icon={<SidebarIcon as={GroupsIcon} />} name="/app/groups" label="Groups" />
            </Sidebar>
        </StyledSection>
    );
}

AppSidebar.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
};

AppSidebar.defaultProps = {
    className: undefined,
    style: undefined,
};

export default AppSidebar;
