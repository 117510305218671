import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Label = styled.h4`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
`;

export const Value = styled.h3`
    font-size: 16px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
`;
