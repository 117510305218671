import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { changeLocale } from '../../i18n';
import StyledSelect from './styled/select';

const languages = [
    { value: 'en', label: 'English' },
    { value: 'es', label: 'Español' },
];

function LanguageSelector(props) {
    const {
        onChangeLanguage,
        style,
        className,
    } = props;
    const { locale = 'en' } = useIntl();

    const handleLanguageChange = (event) => {
        onChangeLanguage(event.target.value);
    };

    return (
        <StyledSelect
            label="Language Selector"
            hideLabel
            style={style}
            options={languages}
            value={locale}
            onChange={handleLanguageChange}
            className={className} />
    );
}

LanguageSelector.propTypes = {
    onChangeLanguage: PropTypes.func,
    style: PropTypes.object,
    className: PropTypes.string,
};

LanguageSelector.defaultProps = {
    onChangeLanguage: () => {},
    style: {},
    className: undefined,
};

function dispatchToProps(dispatch) {
    return bindActionCreators({
        onChangeLanguage: (locale) => dispatch(changeLocale(locale)),
    }, dispatch);
}

export default connect(() => ({}), dispatchToProps)(LanguageSelector);
