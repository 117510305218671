import React from 'react';
import PropTypes from 'prop-types';

export default function CheckmarkCircleIcon(props) {
    const { className } = props;
    return (
        <svg className={className} width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="contacts_groups-copy-3" transform="translate(-1044.000000, -264.000000)" fill="#00D8C7" fillRule="nonzero">
                    <g id="checkmark-circle" transform="translate(1044.000000, 264.000000)">
                        <path d="M7,0 C10.8599548,0 14,3.14004518 14,7 C14,10.8599548 10.8599548,14 7,14 C3.14004518,14 0,10.8599548 0,7 C0,3.14004518 3.14004518,0 7,0 Z M10.5478516,4.69180299 C10.3197021,4.46365358 9.95109557,4.46365358 9.72305299,4.69180299 L9.72305299,4.69180299 L6.34375,8.07099915 L4.86035156,6.58760071 C4.63220215,6.3594513 4.26359557,6.3594513 4.03555299,6.58760071 C3.80740358,6.8156433 3.80740358,7.1843567 4.03555299,7.41239929 L4.03555299,7.41239929 L5.93135071,9.30819701 C6.04510498,9.4219513 6.19442748,9.47920228 6.34375,9.47920228 C6.49307252,9.47920228 6.64239502,9.4219513 6.75614929,9.30819701 L6.75614929,9.30819701 L10.5478516,5.51660156 C10.7758942,5.28845215 10.7758942,4.91984557 10.5478516,4.69180299 Z" id="Combined-Shape" />
                    </g>
                </g>
            </g>
        </svg>
    );
}

CheckmarkCircleIcon.propTypes = {
    className: PropTypes.string,
};
CheckmarkCircleIcon.defaultProps = {
    className: undefined,
};
