import React from 'react';
import PropTypes from 'prop-types';

export default function Power(props) {
    const { className, style } = props;
    return (
        <svg className={className} style={style} fill="#061C3F" width="16px" height="16px" viewBox="0 0 16 16">
            <g id="profile-with-avatar-menu-open" transform="translate(-1200.000000, -170.000000)">
                <g transform="translate(1186.000000, 68.000000)">
                    <g transform="translate(14.000000, 102.000000)">
                        <path d="M11.5507339,1.95442759 C11.184331,1.72371397 10.7002192,1.83379165 10.4695056,2.20009002 C10.238792,2.56649292 10.3487652,3.05060472 10.7151681,3.28131833 C12.4653299,4.38345408 13.5102838,6.27338221 13.5102838,8.33673757 C13.5102838,11.6292414 10.8316225,14.3077982 7.53911869,14.3077982 C4.24661488,14.3077982 1.56805808,11.6291368 1.56805808,8.33673757 C1.56805808,6.27317314 2.61301198,4.38324501 4.36317387,3.28121379 C4.72957677,3.05050018 4.83965445,2.56638838 4.60894083,2.19998548 C4.37822722,1.83358258 3.89411543,1.72360944 3.52771252,1.95421851 C1.31873684,3.34519056 -2.13162821e-13,5.73114773 -2.13162821e-13,8.33673757 C-2.13162821e-13,12.4938686 3.3820922,15.8758563 7.53911869,15.8758563 C11.6962497,15.8758563 15.0783419,12.4937641 15.0783419,8.33673757 C15.0783419,5.73135681 13.7596051,3.34539964 11.5507339,1.95442759 Z" />
                        <path d="M7.53911869,6.46672958 C7.9721118,6.46672958 8.32314773,6.11569365 8.32314773,5.68270054 L8.32314773,0.784029038 C8.32314773,0.351035935 7.9721118,1.49213975e-13 7.53911869,1.49213975e-13 C7.10612559,1.49213975e-13 6.75508966,0.351035935 6.75508966,0.784029038 L6.75508966,5.68270054 C6.75508966,6.11569365 7.10612559,6.46672958 7.53911869,6.46672958 Z" id="Path" />
                    </g>
                </g>
            </g>
        </svg>
    );
}

Power.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};

Power.defaultProps = {
    style: {},
    className: undefined,
};
