import React from 'react';
import PropTypes from 'prop-types';

const AddCard = (props) => {
    const { className } = props;
    return (
        <svg className={className} width="45px" height="32px" viewBox="0 0 45 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <g id="mensajes" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="messages_add-credit-v2" transform="translate(-73.000000, -432.000000)" fillRule="nonzero">
                    <g id="Group-34" transform="translate(73.000000, 432.000000)">
                        <g id="visa" fill="#FAFAFA" stroke="#E3E5ED" strokeWidth="0.25">
                            <path d="M42.1518125,0.445 C42.84839,0.445 43.4788957,0.731722889 43.9353205,1.19501786 C44.3924376,1.6590155 44.675,2.30006831 44.675,3.00804821 L44.675,3.00804821 L44.675,28.991863 C44.675,29.6998713 44.3924284,30.3409341 43.9353174,30.8049409 C43.4788802,31.2682637 42.8483631,31.555 42.1518125,31.555 L42.1518125,31.555 L2.6481875,31.555 C1.95163687,31.555 1.32111982,31.2682637 0.864682618,30.8049409 C0.407571648,30.3409341 0.125,29.6998713 0.125,28.991863 L0.125,28.991863 L0.125,3.00804821 C0.125,2.3000474 0.407566008,1.65901185 0.864669294,1.19502821 C1.32110791,0.731719251 1.95163058,0.445 2.6481875,0.445 L2.6481875,0.445 Z" id="Shape" />
                        </g>
                        <path d="M21.7278478,10 C21.9466002,10 22.1240188,10.1773526 22.1240188,10.396171 L22.1240188,10.396171 L22.124,15.333 L27.123829,15.3339548 C27.3152374,15.3339548 27.4749995,15.4697909 27.5119493,15.6502969 L27.52,15.7301258 C27.52,15.9488782 27.3426474,16.1262968 27.123829,16.1262968 L27.123829,16.1262968 L22.124,16.126 L22.1240188,21.123829 C22.1240188,21.3152951 21.9881827,21.475014 21.8076766,21.511952 L21.7278478,21.52 C21.5090954,21.52 21.3316768,21.3426474 21.3316768,21.123829 L21.3316768,21.123829 L21.331,16.126 L16.396171,16.1262968 C16.2047049,16.1262968 16.044986,15.9904607 16.008048,15.8099546 L16,15.7301258 C16,15.5113733 16.1773526,15.3339548 16.396171,15.3339548 L16.396171,15.3339548 L21.331,15.333 L21.3316768,10.396171 C21.3316768,10.2047626 21.4675129,10.0450005 21.6480189,10.0080507 Z" id="Combined-Shape" stroke="#01B6F5" fill="#01B6F5" />
                    </g>
                </g>
            </g>
        </svg>
    );
};

AddCard.propTypes = {
    className: PropTypes.string,
};
AddCard.defaultProps = {
    className: undefined,
};

export default AddCard;
