import firebase from '../../../firebase';
import { getFirestorePath } from '../../../helpers';

const db = firebase.firestore();

function batchSplit(ref, data, batchAction) {
    if (data.length <= 500) {
        return batchAction(ref, data);
    }
    const first500 = data.slice(0, 500);
    const rest = data.slice(500, data.length);
    return Promise.all([
        batchSplit(ref, first500, batchAction),
        batchSplit(ref, rest, batchAction),
    ]);
}

function batchAdd(collectionPath, data) {
    const batch = db.batch();
    data.forEach((item) => {
        const docRef = db
            .collection(getFirestorePath(collectionPath))
            .doc();

        batch.set(docRef, item);
    });
    return batch.commit();
}

const createGroup = async (data) => {
    const {
        uid,
        name,
        contacts,
    } = data;
    const groupRef = db.collection(getFirestorePath(`users/${uid}/groups`));
    const group = await groupRef.add({
        name,
        members: contacts.length,
        createdAt: new Date(),
        visible: true,
    });
    return batchSplit(
        `users/${uid}/groups/${group.id}/contacts`,
        contacts,
        batchAdd,
    );
};

export default createGroup;
