import React from 'react';
import PropTypes from 'prop-types';
import { Container, Label, Value } from './styled';

export default function TextOutput(props) {
    const {
        className,
        style,
        label,
        value,
    } = props;
    return (
        <Container className={className} style={style}>
            <Label>
                {label}
            </Label>
            <Value>
                {value}
            </Value>
        </Container>
    );
}

TextOutput.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    label: PropTypes.node,
    value: PropTypes.node,
};

TextOutput.defaultProps = {
    className: undefined,
    style: {},
    label: null,
    value: null,
};
