import React from 'react';
import PropTypes from 'prop-types';
import RenderIf from 'react-rainbow-components/components/RenderIf';
import isStandaloneMode from '../../redux/services/app/is-standalone-mode';

export default function RenderIfStandaloneMode(props) {
    const { children } = props;

    return (
        <RenderIf isTrue={isStandaloneMode()}>
            {children}
        </RenderIf>
    );
}

RenderIfStandaloneMode.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};
