import styled from 'styled-components';

export const StyledContainer = styled.span`
    line-height: inherit;
    height: inherit;
    width: 100%;
    transition: all 0.2s ease;
`;

export const StyledInput = styled.input`
    position: absolute !important;
    margin: -1px !important;
    border: 0 !important;
    padding: 0 !important;
    width: 1px !important;
    height: 1px !important;
    overflow: hidden !important;
    clip: rect(0 0 0 0) !important;
    text-transform: none !important;
    white-space: nowrap !important;
    box-sizing: border-box;
`;

export const StyledLabel = styled.label`
    width: 100%;
    padding: 10px 12px;
    border-radius: 16px;
    background-color: ${(props) => props.theme.rainbow.palette.background.main};
    border: 1px solid rgba(151, 151, 151, 0.15);
    font-size: 16px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    transition: all 0.2s ease;
    line-height: 20px;
    display: flex;
    align-items: center;
    user-select: none;

    &:hover {
        cursor: pointer;
        box-shadow: 0 0 2px 0 #d7d9e2;
    }

    ${(props) => props.isChecked
        && `
            box-shadow: 0 0 2px 0 #01B6F5;
            border: 1px solid #01B6F5;

        &:hover {
            cursor: pointer;
            box-shadow: 0 0 2px 0 #01B6F5;
        }
        `};
`;

export const Faux = styled.div`
    width: 20px;
    height: 20px;
    background-color: white;
    display: inline-block;
    margin-right: 12px;
    border: 1px solid ${(props) => props.theme.rainbow.palette.border.divider};
    border-radius: 12px;
    position: relative;
    transition: all 0.2s ease;

    &::after {
        content: '';
        height: 12px;
        width: 12px;
        position: absolute;
        top: 2px;
        left: 2px;
        border-radius: 20px;
        background: transparent;
        opacity: 1;
        transform: scale(0.1, 0.1);
        transition: all 0.2s ease-in;
        box-sizing: border-box;
    }

    ${(props) => props.isChecked
        && `
            border: 2px solid #01B6F5;

            &::after {
                opacity: 1;
                transform: scale(1, 1);
                transition: all 0.2s ease-in;
                background: #01B6F5;
                box-sizing: border-box;
            }
        `};
`;
