import getMessageCount from './getMessageCount';

const MMS_PRICE = 0.05;
// base price is 0.04 (for only one sms) and then additional sms cost 0.02 each one
const SMS_PRICE = 0.02;

const getCost = (body = '', type = 'sms') => {
    if (!body) {
        return 0.0;
    }
    if (type === 'mms') {
        return MMS_PRICE;
    }
    return Number((SMS_PRICE + (getMessageCount(body.length, type) * SMS_PRICE)).toFixed(2));
};

export default getCost;
