import React from 'react';
import RadioCardPicker from '../radioCardPicker';
import CreditCardIcon from '../creditCard';
import CardLoading from '../cardLoading';

const SavedCards = (props) => {
    const {
        cards,
        isLoading,
        onChange,
        value,
    } = props;
    if (isLoading) {
        return (
            <div>
                <CardLoading className="rainbow-m-bottom_x-small rainbow-m-vertical_xx-small" />
                <CardLoading className="rainbow-m-bottom_xx-small rainbow-m-vertical_xx-small" />
            </div>
        );
    }
    return cards.map((card) => {
        return (
            <RadioCardPicker
                className="rainbow-m-bottom_xx-small rainbow-m-vertical_xx-small"
                key={card.id}
                isChecked={card.id === value}
                onChange={onChange}
                id={card.id}
            >
                <div>
                    <CreditCardIcon value={card.brand} />
                    <span>
                        {card.brand}
                        {' '}
                        •••• •••• ••••
                        {' '}
                        {card.last4}
                    </span>
                </div>
            </RadioCardPicker>
        );
    });
};

export default SavedCards;
