import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-rainbow-components';
import AddCreditForm from './components/addCreditForm';
import { AddCreditDrawer, PlusIcon } from './styled';

function AddCreditButton(props) {
    const {
        className,
        style,
    } = props;
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <Button
                className={className}
                style={style}
                onClick={() => setIsOpen(true)}>

                <PlusIcon />
                Add Credit
            </Button>

            <AddCreditDrawer
                header="Add Credit"
                isOpen={isOpen}
                slideFrom="left"
                onRequestClose={() => setIsOpen(false)}>

                <AddCreditForm onRequestClose={() => setIsOpen(false)} />
            </AddCreditDrawer>
        </>
    );
}

AddCreditButton.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
};

AddCreditButton.defaultProps = {
    className: undefined,
    style: {},
};

export default AddCreditButton;
