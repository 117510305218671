import React from 'react';
import PropTypes from 'prop-types';
import RenderIf from 'react-rainbow-components/components/RenderIf';
import isEtMode from '../../redux/services/app/is-et-mode';

export default function RenderIfEtMode(props) {
    const { children } = props;

    return (
        <RenderIf isTrue={isEtMode()}>
            {children}
        </RenderIf>
    );
}

RenderIfEtMode.propTypes = {
    children: PropTypes.object.isRequired,
};
