import React from 'react';
import PropTypes from 'prop-types';
import StyledLink from './styled';

export default function Link({ className, value, to }) {
    return (
        <StyledLink className={className} to={to}>
            {value}
        </StyledLink>
    );
}

Link.propTypes = {
    className: PropTypes.string,
    value: PropTypes.string,
    to: PropTypes.string,
};

Link.defaultProps = {
    className: undefined,
    value: undefined,
    to: undefined,
};
