import React from 'react';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null };
    }

    componentDidCatch(error) {
        this.setState({
            error,
        });
    }

    render() {
        const { error } = this.state;
        // eslint-disable-next-line react/prop-types
        const { children, history } = this.props;

        if (error) {
            setTimeout(() => {
                // eslint-disable-next-line react/prop-types
                history.push('/app/dashboard');
                this.setState({
                    error: null,
                });
            }, 5000);
            return (
                <div>
                    <h2>Something went wrong.</h2>
                </div>
            );
        }
        return children;
    }
}

export default ErrorBoundary;
