import React from 'react';
import propTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import {
    Form,
    CreateButton,
    InfoContainer,
    InfoIcon,
    InfoText,
    InputName,
    Content,
} from './styled';
import ImportDataInput from './importDataInput';
import { showSpinner, hideSpinner } from '../../../components/global-spinner';
import createGroup from './createGroup';
import useCurrentUser from '../../../hooks/useCurrentUser';

function NewGroupForm(props) {
    const { handleSubmit, onRequestClose } = props;
    const dispatch = useDispatch();
    const { uid } = useCurrentUser();
    const onSubmit = async (values) => {
        const { name, contacts } = values;
        dispatch(showSpinner({
            message: 'Creating group.',
        }));
        await createGroup({
            uid,
            name,
            contacts: contacts.data,
        });
        onRequestClose();
        dispatch(hideSpinner());
    };
    return (
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Content>
                <Field
                    component={InputName}
                    name="name"
                    label="Group Name"
                    required
                    placeholder="Enter the group name"
                    className="rainbow-m-top_large rainbow-m-bottom_large" />
                <Field component={ImportDataInput} name="contacts" />
                <CreateButton
                    type="submit"
                    label="Create"
                    variant="brand" />
            </Content>
            <InfoContainer>
                <InfoIcon />
                <InfoText>
                    We support only United States phone numbers.
                </InfoText>
            </InfoContainer>
        </Form>
    );
}

NewGroupForm.propTypes = {
    handleSubmit: propTypes.func.isRequired,
    onRequestClose: propTypes.func,
};

NewGroupForm.defaultProps = {
    onRequestClose: () => {},
};

const validate = (values) => {
    const errors = {};
    if (!values.name) {
        errors.name = 'The group name is a required field.';
    }
    if (!values.contacts) {
        errors.contacts = 'There are not contacts to import. You can import contacts (name, phone) from CSV files.';
    }
    return errors;
};

export default reduxForm({
    form: 'create-group',
    touchOnBlur: true,
    validate,
})(NewGroupForm);
