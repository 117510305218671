/* eslint-disable jsx-a11y/label-has-for */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    RadioButtonGroup,
    RenderIf,
    Modal,
} from 'react-rainbow-components';
import { Picture } from '@rainbow-modules/icons';
import { Field } from 'redux-form';
import SelectImageModal from './selectImageModal';
import {
    TextareaFooter,
    TextContainer,
    TextCount,
    MessageCount,
    PictureButtonIcon,
} from './styled';

const options = [
    { value: 'sms', label: 'SMS' },
    { value: 'mms', label: 'MMS' },
];

export default function FooterTextarea(props) {
    const {
        onSelectType,
        length,
        maxTextCount,
        messagesCount,
        maxMessageCount,
        onSelectImage,
    } = props;
    const [isOpen, setOpenStatus] = useState(false);
    const [type, setType] = useState('sms');
    const handleMessageTypeChange = (event) => {
        setType(event.target.value);
        onSelectType(event);
    };
    const handleSelectImage = (ref) => {
        setOpenStatus(false);
        onSelectImage(ref);
    };
    return (
        <>
            <TextareaFooter>
                <div className="rainbow-flex rainbow-align_center">
                    <Field
                        component={RadioButtonGroup}
                        onChange={handleMessageTypeChange}
                        options={options}
                        size="small"
                        name="type"
                    />
                    <RenderIf isTrue={type === 'mms'}>
                        <PictureButtonIcon
                            variant="border-filled"
                            size="small"
                            shaded
                            icon={<Picture />}
                            className="rainbow-m-left_x-small"
                            onClick={() => setOpenStatus(true)} />
                    </RenderIf>
                </div>
                <TextContainer>
                    <TextCount>{`${length}/${maxTextCount}`}</TextCount>
                    <MessageCount>{`${messagesCount}/${maxMessageCount}`}</MessageCount>
                </TextContainer>
            </TextareaFooter>
            <Modal
                title="Your Images Gallery"
                isOpen={isOpen}
                onRequestClose={() => setOpenStatus(false)}
                size="large">
                <SelectImageModal onSelectImage={handleSelectImage} />
            </Modal>
        </>
    );
}

FooterTextarea.propTypes = {
    onSelectType: PropTypes.func,
    onSelectImage: PropTypes.func,
    length: PropTypes.number,
    maxTextCount: PropTypes.number,
    messagesCount: PropTypes.number,
    maxMessageCount: PropTypes.number,
};

FooterTextarea.defaultProps = {
    onSelectType: () => {},
    onSelectImage: () => {},
    length: undefined,
    maxTextCount: undefined,
    messagesCount: undefined,
    maxMessageCount: undefined,
};
