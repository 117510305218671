import styled from 'styled-components';

export const LoadingContrainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 12px;
    border-radius: 16px;
    background-color: ${(props) => props.theme.rainbow.palette.background.main};
    border: 1px solid rgba(151, 151, 151, 0.15);
    transition: all 0.2s ease;
`;

export const StyledRectangleLoading = styled.div`
    height: 12px;
    object-fit: contain;
    border-radius: 24px;
    z-index: 1000;
    width: 60%;
    background: rgba(215, 217, 226, 0.15)
        linear-gradient(
            90deg,
            rgba(215, 217, 226, 0.01) 0%,
            rgba(215, 217, 226, 0.35) 50%,
            rgba(215, 217, 226, 0.01) 100%
        );
    background-size: 400% 400%;
    animation: gradient 2.5s ease-in-out infinite;

    @keyframes gradient {
        0% {
            background-position: 14% 0;
        }

        50% {
            background-position: 87% 100%;
        }

        100% {
            background-position: 14% 0;
        }
    }
`;

export const StyledSquareLoading = styled(StyledRectangleLoading)`
    height: 30px;
    width: 45px;
    border-radius: 8px;
    margin-right: 12px;
    background: rgba(215, 217, 226, 0.15)
        linear-gradient(
            90deg,
            rgba(215, 217, 226, 0.01) 0%,
            rgba(215, 217, 226, 0.25) 50%,
            rgba(215, 217, 226, 0.01) 100%
        );
`;
