import React from 'react';
import PropTypes from 'prop-types';

export default function Received(props) {
    const { className, style } = props;
    return (
        <svg width="29px" height="24px" viewBox="0 0 29 24" className={className} style={style}>
            <g id="modules" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="received" transform="translate(14.698376, 12.000000) scale(-1, -1) translate(-14.698376, -12.000000) translate(1.000000, 0.000000)" fill="currentColor" fillRule="nonzero">
                    <path d="M27.0516195,10.6459768 L16.7878608,0.382218097 C16.4617169,0.057187935 15.9724733,-0.0420417633 15.5448167,0.134756381 C15.1183295,0.310385151 14.8411879,0.727795824 14.8411879,1.188529 L14.8411879,5.75476566 C8.27922042,6.02388863 2.55435731,10.4544223 0.720556845,16.8031183 C-0.0731693735,19.5561021 -0.0115823666,21.7924455 0.0111925754,22.6352297 L0.0157587007,22.856464 C0.0157587007,23.369652 0.35899768,23.8201392 0.852807425,23.9558422 C0.954320186,23.983239 1.05577726,23.9968817 1.15617633,23.9968817 C1.55075638,23.9968817 1.92707193,23.7904594 2.13577726,23.4403712 C6.65295592,15.8474617 12.6378654,15.6319072 14.8411879,15.846348 L14.8411879,21.7161021 C14.8411879,22.1768353 15.1183295,22.5942459 15.5448167,22.7698747 C15.9724733,22.9477865 16.4617169,22.8485568 16.7878608,22.522413 L27.0516195,12.2586543 C27.4974849,11.8126218 27.4974849,11.0918422 27.0516195,10.6459768 Z" id="Path" />
                </g>
            </g>
        </svg>
    );
}

Received.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};

Received.defaultProps = {
    style: {},
    className: undefined,
};
