import React from 'react';
import PropTypes from 'prop-types';

export default function CampaignIcon(props) {
    const { className } = props;
    return (
        <svg className={className} width="22px" height="21px" viewBox="0 0 22 21" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="daily" transform="translate(-41.000000, -131.000000)">
                    <g id="campaign" transform="translate(41.000000, 131.000000)">
                        <path d="M2.53211049,16.5295093 L3.47034531,16.1554202 L1.56472588,11.3750478 L0.626491057,11.7489425 C0.1163696,11.9524081 -0.132375339,12.5303514 0.0708959158,13.0404729 L1.24038579,15.9741085 C1.44365705,16.4840356 2.02198903,16.7327806 2.53211049,16.5295093" id="Fill-3" fill="#424A60" />
                        <path d="M19.8761429,6.84553846 C20.3592522,8.05680972 19.7682886,9.42995951 18.5572117,9.91287449 C17.3463291,10.3955951 15.9731793,9.80521457 15.4904587,8.59374899 C15.0075437,7.3828664 15.5981186,6.0097166 16.8090012,5.52719028 C18.0200781,5.04408097 19.3934222,5.63446154 19.8761429,6.84553846" id="Fill-5" fill="#424A60" />
                        <path d="M8.34922474,18.9932117 C8.648496,19.7439161 8.28256887,20.5952845 7.53147575,20.8943615 C6.78057697,21.193827 5.92920855,20.8278999 5.62993729,20.0771955 L3.36035341,14.6106753 C3.06108215,13.8597765 3.42720361,13.0086024 4.17829673,12.7093312 C4.92900118,12.4098656 5.78017527,12.775987 6.07944652,13.5266915 L8.34922474,18.9932117 Z" id="Fill-7" fill="#424A60" />
                        <path d="M16.1078708,0.878380567 C16.1212797,0.903643725 16.131385,0.930072874 16.1432392,0.95902834 L18.6839939,7.33268988 L21.2338239,13.7300988 C21.2456781,13.7590543 21.2557834,13.7854834 21.263751,13.8126899 C21.4011437,14.2489652 21.2219696,14.6102283 20.8387469,14.7355725 L20.8387469,14.7355725 C20.7419696,14.7680259 20.5715405,14.7688032 20.3820668,14.6764955 C20.3820668,14.6764955 18.9850141,14.1174024 15.1974838,13.9953619 C13.18984,13.9325927 8.4351194,14.7726899 8.4351194,14.7726899 L8.4351194,14.7726899 L1.72075503,14.7684146 L1.49854288,14.2105021 L1.49838094,14.2105263 L1.26654288,13.6288907 L6.13669673,9.00689879 C6.13669673,9.00689879 10.1659761,6.34552227 11.5795469,4.91893117 C14.2446158,2.22490688 14.874057,0.857975709 14.874057,0.857975709 C14.9480975,0.660728745 15.0720813,0.544323887 15.162057,0.502153846 L15.1647777,0.501182186 C15.5287615,0.328421053 15.9075145,0.467368421 16.1078708,0.878380567 Z" id="Combined-Shape" fill="#BFC6DB" />
                        <path d="M6.13323762,8.97011174 L8.45783681,14.8018202 L2.93744814,17.0022413 C2.70288944,17.095715 2.43801494,16.9878607 2.34551292,16.756217 L0.352444094,11.7562494 C0.260330734,11.5248 0.378290248,11.2642008 0.612848952,11.1707271 L6.13323762,8.97011174 Z" id="Fill-13" fill="#556080" />
                    </g>
                </g>
            </g>
        </svg>
    );
}

CampaignIcon.propTypes = {
    className: PropTypes.string,
};
CampaignIcon.defaultProps = {
    className: undefined,
};
