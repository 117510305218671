import React from 'react';
import PropTypes from 'prop-types';
import {
    LoadingContrainer, StyledRectangleLoading, StyledSquareLoading,
} from './styled';

function CardLoading(props) {
    const {
        className,
        style,
    } = props;

    return (
        <LoadingContrainer className={className} style={style}>
            <StyledSquareLoading />
            <StyledRectangleLoading />
        </LoadingContrainer>
    );
}

CardLoading.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
};

CardLoading.defaultProps = {
    className: undefined,
    style: undefined,
};

export default CardLoading;
