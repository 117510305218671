import React from 'react';
import PropTypes from 'prop-types';
import {
    StyledContainer,
    StyledInput,
    StyledLabel,
    Faux,
} from './styled';

function RadioCardPicker(props) {
    const {
        id,
        children,
        onChange,
        isChecked,
        className,
        style,
    } = props;
    return (
        <StyledContainer className={className} style={style}>
            <StyledInput
                id={id}
                name="paymentOpt"
                as="input"
                type="radio"
                isChecked={isChecked}
                onChange={onChange}
            />
            <StyledLabel htmlFor={id} isChecked={isChecked}>
                <Faux isChecked={isChecked} />
                {children}
            </StyledLabel>
        </StyledContainer>
    );
}

RadioCardPicker.propTypes = {
    id: PropTypes.string.isRequired,
    className: PropTypes.string,
    style: PropTypes.object,
    onChange: PropTypes.func,
    isChecked: PropTypes.bool,
    children: PropTypes.object.isRequired,
};

RadioCardPicker.defaultProps = {
    className: undefined,
    style: undefined,
    isChecked: false,
    onChange: () => {},
};

export default RadioCardPicker;
