import React from 'react';
import PropTypes from 'prop-types';
import {
    FormattedMessage,
    injectIntl,
    defineMessages,
} from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, formValueSelector } from 'redux-form';
import RenderIf from 'react-rainbow-components/components/RenderIf';
import {
    Input,
    Button,
    Modal,
} from 'react-rainbow-components';
import updateProfile from '../../../redux/actions/profile/update-profile';
import hideReauthenticateModal from '../../../redux/actions/profile/hide-reauthenticate-modal';
import handleReauthentication from '../../../redux/actions/profile/handle-reauthentication';
import UserIcon from '../../../components/icons/user';
import EmailIcon from '../../../components/icons/email';
import LockIcon from '../../../components/icons/lock';
import validate from './validate';
import isChangedValue from './isChangedValue';
import ReauthenticateForm from './reauthenticateForm';
import StyledReauthenticateFormTitle from './styled/reauthenticateFormTitle';
import {
    ProfileCard,
    Container,
    StyledSection,
    StyledTitle,
    StyledContent,
    StyledInputContainer,
    StyledAvatarContainer,
    StyledInput,
    StyledAvatarLabel,
    StyledAvatar,
    StyledPersonIcon,
    StyledBottomBar,
} from './styled/index';
import isEtMode from '../../../redux/services/app/is-et-mode';

const translations = defineMessages({
    displayNamePlaceholder: {
        id: 'form.displayName.placeholder',
        defaultValue: 'Enter your name',
    },
    emailPlaceholder: {
        id: 'form.email.placeholder',
        defaultValue: 'Enter your email address',
    },
    phonePlaceholder: {
        id: 'form.phone.placeholder',
        defaultValue: 'Enter your phone number',
    },
    passwordPlaceholder: {
        id: 'form.password.change.placeholder',
        defaultValue: 'Enter new password',
    },
    reauthenticateTitle: {
        id: 'profile.reauthenticate.title',
        defaultValue: 'Renew your credentials',
    },
});

const isEvertransit = isEtMode();

function Form(props) {
    const {
        user,
        isLoading,
        isModalOpen,
        updateProfile,
        handleSubmit,
        hideReauthenticateModal,
        handleReauthentication,
        intl,
        currentValues,
        initialValues,
    } = props;

    const onSubmit = (credentials) => {
        handleReauthentication(credentials, currentValues);
    };

    const isBottomBarVisible = isChangedValue(currentValues, initialValues);
    const isReadOnly = isEvertransit;

    return (
        <Container>
            <ProfileCard>
                <form noValidate onSubmit={handleSubmit(updateProfile)}>
                    <StyledSection>
                        <StyledTitle>
                            Your Information
                        </StyledTitle>
                        <StyledContent>
                            <StyledInputContainer>
                                <StyledInput
                                    readOnly={isReadOnly}
                                    component={Input}
                                    name="displayName"
                                    label={<FormattedMessage id="form.displayName.label" defaultMessage="Name" />}
                                    required
                                    placeholder={
                                        intl.formatMessage(translations.displayNamePlaceholder)
                                    }
                                    icon={<UserIcon />} />
                                <StyledInput
                                    readOnly={isReadOnly}
                                    component={Input}
                                    name="email"
                                    label={<FormattedMessage id="form.email.label" defaultMessage="Email address" />}
                                    required
                                    placeholder={intl.formatMessage(translations.emailPlaceholder)}
                                    type="email"
                                    icon={<EmailIcon />} />
                                <RenderIf isTrue={!isReadOnly}>
                                    <StyledInput
                                        component={Input}
                                        name="password"
                                        label={(
                                            <FormattedMessage
                                                id="form.password.change.label"
                                                defaultMessage="Change password" />
                                        )}
                                        placeholder={
                                            intl.formatMessage(translations.passwordPlaceholder)
                                        }
                                        type="password"
                                        icon={<LockIcon />} />
                                </RenderIf>

                            </StyledInputContainer>
                            <StyledAvatarContainer>
                                <StyledAvatarLabel>
                                    <FormattedMessage id="profile.photo" defaultMessage="Profile Photo" />
                                </StyledAvatarLabel>
                                <StyledAvatar
                                    src={user.photoURL}
                                    icon={<StyledPersonIcon />}
                                    assistiveText="user profile" />
                            </StyledAvatarContainer>
                        </StyledContent>
                        <StyledBottomBar isBottomBarVisible={isBottomBarVisible}>
                            <Button
                                label={<FormattedMessage id="profile.save.changes" defaultMessage="Save changes" />}
                                variant="brand"
                                type="submit"
                                isLoading={isLoading} />
                        </StyledBottomBar>
                    </StyledSection>
                </form>
            </ProfileCard>
            <Modal
                title={intl.formatMessage(translations.reauthenticateTitle)}
                isOpen={isModalOpen}
                onRequestClose={hideReauthenticateModal}>
                <StyledReauthenticateFormTitle>
                    <FormattedMessage
                        id="profile.reauthenticate.message"
                        defaultMessage="This operation is sensitive and requires recent login, please, reauthenticate" />
                </StyledReauthenticateFormTitle>
                <ReauthenticateForm onSubmit={onSubmit} />
            </Modal>
        </Container>
    );
}

Form.propTypes = {
    user: PropTypes.object,
    isLoading: PropTypes.bool,
    isModalOpen: PropTypes.bool.isRequired,
    updateProfile: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
    hideReauthenticateModal: PropTypes.func.isRequired,
    handleReauthentication: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    currentValues: PropTypes.object,
    initialValues: PropTypes.object,
};

Form.defaultProps = {
    user: {},
    isLoading: false,
    updateProfile: () => {},
    currentValues: {},
    initialValues: {},
};

const selector = formValueSelector('profile');
function stateToProps(state) {
    const { profile } = state;
    return {
        isLoading: profile.get('isLoading'),
        isModalOpen: profile.get('showModal'),
        currentValues: {
            displayName: selector(state, 'displayName'),
            email: selector(state, 'email'),
            password: selector(state, 'password'),
        },
    };
}

function dispatchToProps(dispatch) {
    return bindActionCreators({
        updateProfile,
        hideReauthenticateModal,
        handleReauthentication,
    }, dispatch);
}

export default connect(stateToProps, dispatchToProps)(
    reduxForm({
        form: 'profile',
        touchOnBlur: false,
        validate,
    })(injectIntl(Form)),
);
