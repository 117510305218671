import React from 'react';
import PropTypes from 'prop-types';
import RenderIf from 'react-rainbow-components/components/RenderIf';
import {
    ContainerCard, Label, Value, Content, IconContainer, ValueContainer,
} from './styled';

export default function InfoTile(props) {
    const {
        value,
        variant,
        label,
        icon,
        className,
        style,
        size,
    } = props;
    const hasIcon = !!icon;

    return (
        <ContainerCard className={className} style={style}>
            <Content>
                <Label>{label}</Label>
                <ValueContainer>
                    <Value variant={variant} size={size}>{value}</Value>
                </ValueContainer>
            </Content>
            <RenderIf isTrue={hasIcon}>
                <IconContainer size={size}>{icon}</IconContainer>
            </RenderIf>
        </ContainerCard>
    );
}

InfoTile.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    icon: PropTypes.node,
    className: PropTypes.string,
    label: PropTypes.oneOfType([
        PropTypes.string, PropTypes.node,
    ]),
    style: PropTypes.object,
    size: PropTypes.oneOf(['small', 'medium']),
    variant: PropTypes.oneOf(['info', 'error']),
};

InfoTile.defaultProps = {
    icon: undefined,
    value: 0,
    className: undefined,
    label: undefined,
    style: undefined,
    size: 'medium',
    variant: 'info',
};
