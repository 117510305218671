import styled from 'styled-components';
import { Card, Avatar } from 'react-rainbow-components';
import { Field } from 'redux-form';
import PersonIcon from '../../../../components/icons/person';

export const Container = styled.section`
    padding: 10px 32px;
`;

export const ProfileCard = styled(Card)`
    width: fit-content;
    padding: 8px 32px 16px 32px;
`;

export const StyledSection = styled.section`
    display: flex;
    flex-direction: column;

    @media (max-width: 620px) {
        overflow: auto;
    }
`;

export const StyledTitle = styled.h1`
    font-size: 22px;
    color: #01b6f5;
    margin-top: 12px;
    font-weight: 400;
`;

export const StyledContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-shrink: 0;
    padding-top: 24px;

    @media (max-width: 620px) {
        flex-direction: column-reverse;
        align-items: center;
        padding-bottom: 64px;
    }
`;

export const StyledInputContainer = styled.div`
    @media (max-width: 620px) {
        width: 100%;
        padding: 0 24px 24px 24px;
    }
`;

export const StyledInput = styled(Field)`
    width: 320px;
    margin-bottom: 24px;
    flex-shrink: 0;

    &[disabled] {
        color: #a4a7b5;
        padding-left: 2.29rem;
    }

    @media (max-width: 620px) {
        margin-right: 0;
        width: 100%;
    }
`;

export const StyledAvatarContainer = styled.div`
    margin-left: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;

    @media (max-width: 620px) {
        margin: 0 0 24px 0;
    }
`;

export const StyledAvatarLabel = styled.span`
    color: #576574;
    margin-top: 4px;
`;

export const StyledAvatar = styled(Avatar)`
    width: 126px;
    height: 126px;
    box-shadow: 0 1px 2px 0 #e3e5ed;
    border: solid 1px #e3e5ed;
    border-radius: 100px;
    margin-top: 4px;
`;

export const StyledPersonIcon = styled(PersonIcon)`
    width: 60px;
    height: 60px;
`;

export const StyledBottomBar = styled.div`
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 1px 4px #e3e5ed;
    padding: 12px 0;
    left: 0;
    position: fixed;
    z-index: 1000;
    bottom: 0;
    width: 100%;
    opacity: 0;
    transform: translateY(100%);
    transition: transform 250ms linear, opacity 250ms linear;

    @media (max-width: 620px) {
        margin-top: 0;
    }

    ${(props) => props.isBottomBarVisible
        && `
            opacity: 1;
            transform: translateY(0%);
        `};
`;
