import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
    Button,
    Column,
} from 'react-rainbow-components';
import { useCollection } from '@rainbow-modules/firebase-hooks';
import NewCampaignForm from './newCampaignForm';
import getCurrentUser from '../../../redux/services/firebase/get-current-user';
import {
    Container,
    CampaignCard,
    CampaignTable,
    Drawer,
    Title,
    Header,
} from './styled';
import FormatedDateCol from '../../../components/formatted-date-col';
import Link from '../../../components/link';
import { getFirestorePath } from '../../../helpers';

const initialValues = {
    type: 'sms',
};

// eslint-disable-next-line react/prop-types
const Cost = ({ value }) => {
    return new Intl.NumberFormat('en', { style: 'currency', currency: 'usd' }).format(value);
};

// eslint-disable-next-line react/prop-types
const CampaignName = ({ value, row: { id } }) => {
    const { pathname } = useLocation();
    const groupDetails = `${pathname}/${id}`;
    return <Link to={groupDetails} value={value} />;
};

const query = (refs) => refs.orderBy('createdAt', 'desc');

function Campaigns() {
    const [isOpen, setIsOpen] = useState(false);
    const [campaigns, isLoading] = useCollection({
        path: getFirestorePath(`users/${getCurrentUser().uid}/campaigns`),
        query,
    });

    return (
        <Container>
            <CampaignCard>
                <Header>
                    <Title>Your Campaigns</Title>
                    <Button
                        variant="brand"
                        label="New Campaign"
                        onClick={() => setIsOpen(true)} />
                </Header>
                <CampaignTable keyField="id" data={campaigns} isLoading={isLoading}>
                    <Column header="Campaign Name" field="data.name" component={CampaignName} />
                    <Column header="Cost" field="data.cost" component={Cost} />
                    <Column header="Sent to" field="data.group.name" />
                    <Column header="Date" field="data.createdAt" component={FormatedDateCol} />
                </CampaignTable>
            </CampaignCard>
            <Drawer
                header="New Campaign"
                isOpen={isOpen}
                slideFrom="right"
                onRequestClose={() => setIsOpen(false)}
                footer={(
                    <div className="rainbow-flex rainbow-align-content_center">
                        <Button
                            label="Cancel"
                            className="rainbow-m-horizontal_medium"
                            onClick={() => setIsOpen(false)}
                        />
                        <Button
                            variant="brand"
                            label="Send"
                            type="submit"
                            form="create-campaign"
                            className="rainbow-m-horizontal_medium rainbow-p-horizontal_large"
                        />
                    </div>
                )}
            >
                <NewCampaignForm
                    onResquestClose={() => setIsOpen(false)}
                    initialValues={initialValues}
                />
            </Drawer>
        </Container>
    );
}

export default Campaigns;
