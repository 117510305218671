import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    Column,
    Badge,
    Breadcrumb,
    Spinner,
    // PicklistOption,
} from 'react-rainbow-components';
import { useDoc } from '@rainbow-modules/firebase-hooks';
import {
    Container,
    MembersCard,
    MembersTable,
    Name,
    SearchInput,
    StyledBreadcrumbs,
    Actions,
    SearchContainer,
    StyledButton,
    Icon,
    Arrow,
} from './styled';
import getCurrentUser from '../../../redux/services/firebase/get-current-user';
import MembersResume from './membersResume';
import { navigateTo } from '../../../history';
import useContactsOnce from '../../../hooks/useContacts';
import { getFirestorePath } from '../../../helpers';

// eslint-disable-next-line react/prop-types
const StatusBadge = ({ value }) => {
    if (value) {
        return <Badge label="unsubscribed" />;
    }
    return null;
};

const GroupDetails = () => {
    const { id } = useParams();
    const [phoneSearch, setPhoneSearch] = useState();
    const [contacts, isLoadingContacts] = useContactsOnce({
        uid: getCurrentUser().uid,
        groupId: id,
        phone: phoneSearch,
    });

    const [searchInputValue, setSearchInputValue] = useState();

    const [groupDoc, isLoadingGroup] = useDoc({
        path: getFirestorePath(`users/${getCurrentUser().uid}/groups/${id}`),
    });
    if (isLoadingGroup || !groupDoc || !groupDoc.data) {
        return <Spinner />;
    }

    const { members, name, unsubscribes } = groupDoc.data;

    return (
        <Container>
            <StyledBreadcrumbs>
                <Breadcrumb label="Groups"
                    onClick={() => navigateTo('/app/groups')}
                />
                <Breadcrumb label="Group Details" />
            </StyledBreadcrumbs>
            <MembersResume total={members} unsubscribes={unsubscribes} />
            <MembersCard>
                <div className="rainbow-m-horizontal_x-large rainbow-m-vertical_medium rainbow-flex rainbow-justify_spread rainbow-align_center">
                    <Name>{name}</Name>
                    <Actions>
                        <SearchContainer>
                            <SearchInput
                                placeholder="Find Member by phone number"
                                iconPosition="right"
                                icon={<Arrow />}
                                value={searchInputValue}
                                onChange={(e) => setSearchInputValue(e.target.value)}
                            />
                            <StyledButton variant="neutral" icon={<Icon />} onClick={() => setPhoneSearch(searchInputValue)} />
                        </SearchContainer>
                    </Actions>
                </div>
                <MembersTable showRowNumberColumn keyField="id" data={contacts} isLoading={isLoadingContacts}>
                    <Column header="Phone number" field="data.phone" />
                    <Column header="Name" field="data.name" />
                    <Column header="Tags" field="data.unsubscribe" component={StatusBadge} />
                </MembersTable>
            </MembersCard>
        </Container>
    );
};

export default GroupDetails;
