import React from 'react';
import PropTypes from 'prop-types';

export default function Pending(props) {
    const { className } = props;
    return (
        <svg className={className} width="12px" height="12px" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Dashboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path d="M5.98986038,2.39808173e-14 C9.29271945,2.39808173e-14 11.9797208,2.68716753 11.9797208,5.99002655 C11.9797208,9.2927194 9.29271945,11.9798869 5.98986038,11.9798869 C2.68700136,11.9798869 7.90478794e-14,9.29288562 7.90478794e-14,5.99002655 C7.90478794e-14,2.68716753 2.68700136,2.39808173e-14 5.98986038,2.39808173e-14 Z M5.94614366,2.95229387 C5.58477396,2.95229387 5.29172209,3.2453457 5.29172209,3.6067154 L5.29172209,6.03357711 C5.29172209,6.39511302 5.58477396,6.68799864 5.94614366,6.68799864 L8.37300536,6.68799864 C8.73437502,6.68799864 9.02742689,6.39511302 9.02742689,6.03357711 C9.02742689,5.67204119 8.73437502,5.37898936 8.37300536,5.37898936 L6.6007314,5.37898936 L6.6007314,3.6067154 C6.6007314,3.2453457 6.30767953,2.95229387 5.94614366,2.95229387 Z" id="pending" fill="currentColor" fillRule="nonzero" />
            </g>
        </svg>
    );
}

Pending.propTypes = {
    className: PropTypes.string,
};
Pending.defaultProps = {
    className: undefined,
};
