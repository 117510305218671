import React from 'react';
import PropTypes from 'prop-types';

export default function Logo(props) {
    const { className } = props;
    return (
        <svg className={className} width="53px" height="48px" viewBox="0 0 53 48" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <polygon id="path-1" points="1.68763449e-05 4.4439237e-05 52.3202192 4.4439237e-05 52.3202192 47.9103122 1.68763449e-05 47.9103122" />
            </defs>
            <g id="Groups" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="contacts_groups" transform="translate(-26.000000, -27.000000)">
                    <g id="Group-17" transform="translate(9.000000, 10.000000)">
                        <g id="logo" transform="translate(17.000000, 17.000000)">
                            <mask id="mask-2" fill="white">
                                <use xlinkHref="#path-1" />
                            </mask>
                            <g id="Clip-2" />
                            <path d="M43.4483945,35.7643397 C42.261326,34.4784219 41.2279014,33.3621479 40.1979836,32.2430247 C38.8816,30.8126685 38.8439014,29.0550795 40.1011068,27.8629699 C41.2515726,26.7723397 43.0709699,26.9728877 44.3781479,28.3651068 C46.6979288,30.8365589 48.9868055,33.3373808 51.3217096,35.7943671 C52.3156822,36.8402849 52.5824219,38.0240658 52.0552986,39.3119562 C51.573326,40.4891616 50.493874,40.8512438 49.3019836,40.9601753 C46.5409973,41.2124493 43.7832986,41.500011 41.0207781,41.7316822 C40.3349699,41.7891068 39.7822027,41.9964493 39.2443397,42.4334904 C33.8683397,46.8023671 27.6954082,48.5415452 20.8555178,47.708011 C16.3479014,47.1587507 12.2494904,45.4325041 8.80466849,42.4380932 C1.10998356,35.7494356 -1.69812603,27.2755726 1.00214795,17.516011 C3.60269589,8.11809315 10.1028603,2.30592877 19.7515178,0.459134247 C27.6607781,-1.05472877 34.6043397,1.2205589 40.5423123,6.61716164 C41.9599562,7.90549041 42.3005589,8.96280548 41.7740932,10.2504767 C40.9567781,12.2502575 38.7441753,12.765326 37.1571068,11.2714082 C35.6346959,9.83842192 34.0094904,8.57617534 32.1447233,7.61003836 C22.1063671,2.41003836 9.19370959,8.21168219 6.41935342,19.1828603 C4.09891507,28.3596274 8.33387397,37.1317918 17.1485589,40.8650521 C23.4595726,43.5381479 31.2702027,42.3436274 36.4489425,37.2328329 C36.959189,36.7296 37.5367233,36.4152986 38.2725041,36.3442849 C39.9266411,36.1847233 41.5761753,35.9773808 43.4483945,35.7643397" id="Fill-1" fill="#01B6F5" mask="url(#mask-2)" />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

Logo.propTypes = {
    className: PropTypes.string,
};
Logo.defaultProps = {
    className: undefined,
};
