import styled from 'styled-components';

export const PhoneBorder = styled.div`
    background-color: #211f21;
    border-radius: 40px;
    border: 4px double #211f21;
    padding: 0.6rem;
    max-width: 320px;
    min-width: 320px;
    min-height: 420px;
    margin: 0 auto;
    box-sizing: border-box;
    box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.15);
    transition: 0.2s ease-in-out;
    animation: fadeInRight 0.3s ease 0s 1 normal both;
`;

export const ScreenContainer = styled.div`
    background-color: #f7f8f9;
    display: flex;
    flex-direction: column;
    border-radius: 28px;
    height: 100%;
    position: relative;

    &::before {
        content: "";
        height: 160px;
        width: 3px;
        background: linear-gradient(
            to top,
            #211f21 0%,
            #211f21 29%,
            transparent 30%,
            transparent 39%,
            #211f21 40%,
            #211f21 69%,
            transparent 70%,
            transparent 79%,
            #211f21 80%,
            #211f21 100%
        );
        position: absolute;
        left: -17px;
        top: 10%;
        border-radius: 2px 0 0 2px;
    }

    &::after {
        content: "";
        height: 70px;
        width: 4px;
        background-color: #211f21;
        position: absolute;
        right: -18px;
        top: 22%;
        border-radius: 0 2px 2px 0;
    }
`;

export const SensorsContainer = styled.div`
    margin-top: -2px;
    text-align: center;
    height: 21px;
`;

export const PhoneNumberContainer = styled.div`
    display: block;
    border-bottom: 0.5px solid #dbe1e5;
    text-align: center;
    padding: 0 0.5rem;
    font-size: 0.75rem;
    font-weight: 400;
    height: 38px;
    line-height: 38px;
    padding-top: 4px;
    color: #404040;
`;

export const MessagesContainer = styled.div`
    padding: 1rem;
    width: 100%;
    flex: 1;
    overflow: auto;
    margin: 0 auto;
    position: relative;
    box-sizing: border-box;
    background-color: white;
    border-radius: 0 0 28px 28px;
`;

export const Message = styled.div`
    font-size: 15px;
    letter-spacing: 0.5;
    font-weight: 400;
    line-height: 1.4rem;
    color: #404040;
    position: relative;
    margin-bottom: 10px;
    word-break: break-word;
    word-wrap: break-word;
    width: 83%;
    box-sizing: border-box;
    white-space: pre-line;
    clear: both;
    background-color: #ebedf0;
    padding: 4px 12px 4px 13px;
    border-radius: 1rem;
    animation: fadeInLeft 0.3s ease 0s 1 normal both;
    min-height: 34px;

    &::before {
        content: "";
        position: absolute;
        z-index: 0;
        bottom: 0;
        left: -7px;
        height: 20px;
        width: 20px;
        background: #ebedf0;
        border-bottom-right-radius: 15px;
    }

    &::after {
        content: "";
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: -10px;
        width: 10px;
        height: 20px;
        background: white;
        border-bottom-right-radius: 10px;
    }
`;

export const StyledImage = styled.img`
    width: 80%;
    margin-bottom: 12px;
`;
