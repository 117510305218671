import React from 'react';
import PropTypes from 'prop-types';

export default function ErrorCircleIcon(props) {
    const { className } = props;
    return (
        <svg className={className} width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="daily-copy-8" transform="translate(-1290.000000, -713.000000)" fillRule="nonzero">
                    <g id="Group-11" transform="translate(1290.000000, 712.000000)">
                        <g id="error" transform="translate(0.000000, 1.000000)">
                            <circle id="Oval" fill="#FE4849" cx="7.98442105" cy="7.98442105" r="7.98442105" />
                            <path d="M15.968807,7.98442105 C15.968807,12.3758596 12.3758246,15.9688421 7.98438596,15.9688421 C5.43936842,15.9688421 3.19375439,14.8210877 1.7465614,12.9747018 C3.09392982,14.0725614 4.84052632,14.7212982 6.73680702,14.7212982 C11.1282456,14.7212982 14.7212281,11.1283158 14.7212281,6.73687719 C14.7212281,4.84059649 14.0724912,3.094 12.9746316,1.74663158 C14.8210526,3.19375439 15.968807,5.43936842 15.968807,7.98442105 Z" id="Shape" fill="#EA4243" />
                            <path d="M7.45976053,8.62781482 L7.25678122,5.59071209 C7.21872636,4.99881455 7.2,4.57388872 7.2,4.31617545 C7.2,3.96537229 7.29194583,3.69164226 7.47553643,3.49516599 C7.65936788,3.29802738 7.90178696,3.2 8.20170981,3.2 C8.56533842,3.2 8.80866069,3.32572554 8.93101428,3.57717662 C9.05336787,3.82886855 9.11484573,4.19159396 9.11484573,4.66450986 C9.11484573,4.94365909 9.09997303,5.22732433 9.07070933,5.51412067 L8.7978825,8.64021878 C8.76837794,9.01239768 8.70503346,9.29762847 8.60748778,9.49657348 C8.51018296,9.69503679 8.34929281,9.79420823 8.12553988,9.79420823 C7.89709031,9.79420823 7.73866891,9.69846895 7.65015524,9.50584634 C7.56115986,9.31382587 7.49763474,9.02094798 7.45976053,8.62781482 Z M8.16359474,12.8 C7.90564061,12.8 7.68032214,12.7168453 7.48836188,12.5499338 C7.29598013,12.383203 7.2,12.1495751 7.2,11.8494716 C7.2,11.5872423 7.29194583,11.3641517 7.47553643,11.1800794 C7.65936788,10.996248 7.88468636,10.9043021 8.15101014,10.9043021 C8.41751457,10.9043021 8.64421795,10.9960071 8.8325654,11.1800794 C9.02067201,11.3639109 9.11484573,11.5871821 9.11484573,11.8494716 C9.11484573,12.1452999 9.01976881,12.3776031 8.82913324,12.5468027 C8.63897938,12.7154604 8.41727371,12.8 8.16359474,12.8 Z" id="Shape" fill="#FFFFFF" />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

ErrorCircleIcon.propTypes = {
    className: PropTypes.string,
};
ErrorCircleIcon.defaultProps = {
    className: undefined,
};
