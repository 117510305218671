import React from 'react';
import PropTypes from 'prop-types';

const Done = (props) => {
    const { className } = props;
    return (
        <svg className={className} width="30px" height="30px" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <title>done</title>
            <desc>Created with Sketch.</desc>
            <g id="google-registration" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="User-profile-changed-successfuly" transform="translate(-150.000000, -19.000000)" fill="#FFFFFF" fillRule="nonzero">
                    <g id="Group-2" transform="translate(0.000000, 1.000000)">
                        <g id="done" transform="translate(150.000000, 18.000000)">
                            <path d="M14.9727368,29.6454737 C6.86920805,29.6454737 0.3,23.0762656 0.3,14.9727368 C0.3,6.86920805 6.86920805,0.3 14.9727368,0.3 C23.0762656,0.3 29.6454737,6.86920805 29.6454737,14.9727368 C29.6454737,23.0762656 23.0762656,29.6454737 14.9727368,29.6454737 Z M14.9727368,28.2454737 C22.303067,28.2454737 28.2454737,22.303067 28.2454737,14.9727368 C28.2454737,7.6424067 22.303067,1.7 14.9727368,1.7 C7.6424067,1.7 1.7,7.6424067 1.7,14.9727368 C1.7,22.303067 7.6424067,28.2454737 14.9727368,28.2454737 Z" id="Oval" />
                            <path d="M23.4918828,10.4269687 C23.1628687,9.57857435 22.4924462,9.71248984 21.7659673,9.8564335 C21.3321082,9.94685604 19.402418,10.5117011 16.3491786,13.7311941 C15.0821363,15.0673631 14.2463054,16.1326025 13.6959955,16.9449969 C13.3598265,16.5322645 12.9746997,16.090687 12.570249,15.6860673 C11.326418,14.4425743 9.93931944,13.5886025 9.88100958,13.5527152 C9.29892507,13.1968842 8.53903775,13.3799828 8.18219268,13.9616166 C7.82579831,14.5432504 8.00833352,15.3043772 8.58951662,15.6615603 C8.60140395,15.6690532 9.79255888,16.4055039 10.821573,17.4347997 C11.8709814,18.4843208 12.8217983,19.9036448 12.8311504,19.9173913 C13.0626434,20.2654476 13.4513194,20.4685462 13.8602208,20.4685462 C13.930418,20.4685462 14.0012913,20.4626307 14.0718265,20.4504053 C14.554418,20.3665743 14.94208,20.0057293 15.0602772,19.5302927 C15.0632631,19.518856 15.553573,18.1637574 18.1436575,15.4329969 C20.2297983,13.2328279 21.6210096,12.5335603 22.1030377,12.3395884 C22.1076575,12.3387997 22.1109814,12.3384617 22.1172349,12.3371659 C22.1149251,12.3377293 22.1328406,12.3294476 22.161911,12.3163772 C22.2446716,12.2853349 22.2890096,12.2734476 22.2915448,12.2728279 C22.2677701,12.2787434 22.2554321,12.2802082 22.2554321,12.2802082 L22.2546434,12.2759265 C22.4776856,12.1786307 22.8952631,11.9989687 22.9043899,11.9951377 C23.5331223,11.7368279 23.737573,11.0605462 23.4918828,10.4269687 Z" id="Shape" />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

Done.propTypes = {
    className: PropTypes.string,
};
Done.defaultProps = {
    className: undefined,
};

export default Done;
