import { useDoc } from '@rainbow-modules/firebase-hooks';
import useCurrentUser from './useCurrentUser';
import { getFirestorePath } from '../helpers';

export default function useCurrentCredit() {
    const { uid } = useCurrentUser();
    const [user] = useDoc({ path: getFirestorePath(`users/${uid}`) });
    if (user) {
        return user.data.credit || 0;
    }
    return 0;
}
