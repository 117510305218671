/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import { Chip } from 'react-rainbow-components';
import PropTypes from 'prop-types';
import { PictureIcon } from './styled';

export default function FileChip(props) {
    const { imageRef, onDelete } = (props);
    if (imageRef) {
        return (
            <Chip
                onDelete={onDelete}
                className="rainbow-m-top_medium rainbow-m-horizontal_medium"
                label={(
                    <span className="rainbow-align-content_center">
                        <PictureIcon />
                        <span className="rainbow-m-left_small">{imageRef.name}</span>
                    </span>
                )}
            />
        );
    }
    return null;
}

FileChip.propTypes = {
    onDelete: PropTypes.func,
    imageRef: PropTypes.object,
};

FileChip.defaultProps = {
    onDelete: () => {},
    imageRef: undefined,
};
