import React from 'react';
import PropTypes from 'prop-types';

const Visa = (props) => {
    const { className } = props;
    return (
        <svg className={className} width="45px" height="32px" viewBox="0 0 45 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <g id="mensajes" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="messages_add-credit-v2" transform="translate(-73.000000, -310.000000)" fillRule="nonzero">
                    <g id="Group-17" transform="translate(30.000000, 298.000000)">
                        <g id="visa" transform="translate(43.000000, 12.000000)">
                            <path d="M42.1518125,0.445 C42.84839,0.445 43.4788957,0.731722889 43.9353205,1.19501786 C44.3924376,1.6590155 44.675,2.30006831 44.675,3.00804821 L44.675,3.00804821 L44.675,28.991863 C44.675,29.6998713 44.3924284,30.3409341 43.9353174,30.8049409 C43.4788802,31.2682637 42.8483631,31.555 42.1518125,31.555 L42.1518125,31.555 L2.6481875,31.555 C1.95163687,31.555 1.32111982,31.2682637 0.864682618,30.8049409 C0.407571648,30.3409341 0.125,29.6998713 0.125,28.991863 L0.125,28.991863 L0.125,3.00804821 C0.125,2.3000474 0.407566008,1.65901185 0.864669294,1.19502821 C1.32110791,0.731719251 1.95163058,0.445 2.6481875,0.445 L2.6481875,0.445 Z" id="Shape" stroke="#E3E5ED" strokeWidth="0.25" fill="#FFFFFF" />
                            <path d="M10.08,16 L8.94631288,11.2716923 C8.75252424,10.6196448 8.18860401,10.4256621 7.48398662,10.4 L2.28630181,10.4 L2.24,10.6160374 C6.28824942,11.4692825 8.9668705,13.5276635 10.08,16 Z" id="Shape" fill="#F7981D" />
                            <g id="Group" transform="translate(5.600000, 9.280000)" fill="#293688">
                                <polygon id="Shape" points="15.5539466 0.873571877 12.432575 0.873571877 10.4831312 12.3448713 13.5993315 12.3448713" />
                                <path d="M6.26257902,12.3333584 L11.1716858,0.881393597 L7.86818384,0.881393597 L4.8143005,8.70557439 L4.48834143,7.52423101 C3.88971263,6.10305085 2.17884379,4.05753923 0.170325851,2.76985562 L2.96188181,12.3372254 L6.26257902,12.3333584 Z" id="Shape" />
                                <path d="M24.4568627,8.59492781 C24.4684321,7.29256748 23.6344712,6.29920904 21.8373578,5.4787194 C20.7451889,4.94877589 20.0795102,4.59539234 20.087486,4.05762712 C20.087486,3.58252354 20.6512313,3.07217828 21.8726795,3.07217828 C22.8947308,3.05644696 23.6306148,3.28020088 24.2061924,3.51186441 L24.4882403,3.64149404 L24.9112246,1.15638418 C24.2925247,0.924632768 23.3253404,0.6773258 22.1157245,0.6773258 C19.03432,0.6773258 16.8653205,2.2320904 16.8456876,4.46198368 C16.8260547,6.11096045 18.3963378,7.02961707 19.5786077,7.57915882 C20.7922553,8.13933459 21.1994631,8.49781544 21.1916625,9.00033898 C21.183862,9.76871312 20.2244782,10.1191086 19.3279374,10.1191086 C18.0789681,10.1191086 17.4172335,9.94641557 16.3909751,9.517715 L15.9921814,9.33403641 L15.5541219,11.90457 C16.2818546,12.2235028 17.6285503,12.5022724 19.0265195,12.5138732 C22.3033767,12.5138732 24.4333733,10.9788826 24.4568627,8.59492781 Z" id="Shape" />
                                <path d="M28.1645048,10.6254991 C28.5207897,10.6254991 31.7038647,10.6294539 32.1582266,10.6294539 C32.2521842,11.0288889 32.5380009,12.348914 32.5380009,12.348914 L35.431315,12.348914 L32.9059429,0.885436284 L30.4981054,0.885436284 C29.7502138,0.885436284 29.1944443,1.08959196 28.8654176,1.83537979 L24.2337135,12.348914 L27.511009,12.348914 C27.5107461,12.348914 28.0431138,10.9358192 28.1645048,10.6254991 Z M30.3021267,5.07032015 C30.2867009,5.10169492 30.5606853,4.4068801 30.7173104,3.97501569 L30.9286273,4.96424357 C30.9286273,4.96424357 31.5239255,7.70465788 31.6490854,8.27784055 L29.0610457,8.27784055 C29.3195166,7.6183553 30.3021267,5.07032015 30.3021267,5.07032015 Z" id="Shape" />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

Visa.propTypes = {
    className: PropTypes.string,
};
Visa.defaultProps = {
    className: undefined,
};

export default Visa;
