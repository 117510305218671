import stripePromise from './stripe';
import firebase from '../../../firebase';
import { getFunctionName } from '../../../helpers';

const createSession = firebase.functions().httpsCallable(getFunctionName('createCheckoutSession'));

const goCheckout = async (opts) => {
    const { data: { id: sessionId } } = await createSession({
        ...opts,
        successUrl: document.location.href,
        cancelUrl: document.location.href,
    });
    const stripe = await stripePromise;
    await stripe.redirectToCheckout({
        sessionId,
    });
};

export default goCheckout;
