import React from 'react';
import PropTypes from 'prop-types';

export default function GroupsIcon(props) {
    const { className } = props;
    return (
        <svg className={className} width="24px" height="21px" viewBox="0 0 24 21" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
                    <stop stopColor="#5FD4FC" offset="0%" />
                    <stop stopColor="#16ACD9" offset="100%" />
                </linearGradient>
                <path d="M5.82,16.5774066 C7.65444724,13.2280753 10.6792878,11.1398631 14.5844895,11.1398631 C18.3016179,11.1398631 21.5150554,13.5406404 23.3584615,16.5774066 C23.3584615,16.5774066 20.4353846,19.9384615 14.5844895,19.9384615 C8.74307692,19.9384615 5.82,16.6343736 5.82,16.5774066 Z" id="path-2" />
                <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-3">
                    <stop stopColor="#1AC4FF" offset="0%" />
                    <stop stopColor="#31B2DA" offset="100%" />
                </linearGradient>
                <path d="M-2.0783375e-13,14.2830066 C1.19239071,12.1059412 3.15853708,10.7486033 5.69691818,10.7486033 C6.97752283,10.7486033 8.27202239,11.1726171 9.28483235,11.8785825 C5.90636863,13.7660143 5.42427134,16.0203235 4.99282731,16.4307692 C1.78898116,16.1353797 -2.0783375e-13,14.3170194 -2.0783375e-13,14.2830066 Z" id="path-4" />
            </defs>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="daily" transform="translate(-41.000000, -235.000000)" fillRule="nonzero">
                    <g id="Group-25" transform="translate(41.000000, 235.400000)">
                        <path d="M14.5838406,8.97230769 C12.1099308,8.97230769 10.0976868,6.96006372 10.0976868,4.48615384 C10.0976868,2.01224391 12.1099308,1.52411417e-13 14.5838406,1.52411417e-13 C17.0577506,1.52411417e-13 19.0699945,2.01224391 19.0699945,4.48615384 C19.0699945,6.96006372 17.0577506,8.97230769 14.5838406,8.97230769 Z" id="Shape-path" fill="#E9C2A5" />
                        <g id="Line-2">
                            <use fill="#01B6F5" xlinkHref="#path-2" />
                            <use fill="url(#linearGradient-1)" xlinkHref="#path-2" />
                        </g>
                        <path d="M5.69649642,9.33969231 C4.088455,9.33969231 2.78049645,8.03173373 2.78049645,6.4236923 C2.78049645,4.81565085 4.088455,3.50769231 5.69649642,3.50769231 C7.30453791,3.50769231 8.61249642,4.81565085 8.61249642,6.4236923 C8.61249642,8.03173373 7.30453791,9.33969231 5.69649642,9.33969231 Z" id="Shape-path" fill="#E9C2A5" />
                        <g id="Line-2">
                            <use fill="#01B6F5" xlinkHref="#path-4" />
                            <use fill="url(#linearGradient-3)" xlinkHref="#path-4" />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

GroupsIcon.propTypes = {
    className: PropTypes.string,
};
GroupsIcon.defaultProps = {
    className: undefined,
};
