import getPaymentIntent from './getPaymentIntent';
import stripePromise from './stripe';

const processPayment = async ({ amount, paymentMethod }) => {
    const { clientSecret } = await getPaymentIntent({ amount, paymentMethod });
    const stripe = await stripePromise;
    return stripe.confirmCardPayment(clientSecret);
};

export default processPayment;
