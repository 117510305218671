import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { StyledLogo } from './styled';

export default function LogoLink({ className }) {
    return (
        <Link to="/home">
            <StyledLogo className={className} />
        </Link>
    );
}

LogoLink.propTypes = {
    className: PropTypes.string,
};

LogoLink.defaultProps = {
    className: undefined,
};
