import React from 'react';
import PropTypes from 'prop-types';

export default function Unknown(props) {
    const { className, style } = props;
    return (
        <svg className={className} style={style} width="45px" height="32px" viewBox="0 0 45 32">
            <g id="mensajes" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="unknown" fillRule="nonzero">
                    <g id="Group-34" fill="#FAFAFA" stroke="#E3E5ED" strokeWidth="0.25">
                        <g id="visa">
                            <path d="M42.1518125,0.445 C42.84839,0.445 43.4788957,0.731722889 43.9353205,1.19501786 C44.3924376,1.6590155 44.675,2.30006831 44.675,3.00804821 L44.675,3.00804821 L44.675,28.991863 C44.675,29.6998713 44.3924284,30.3409341 43.9353174,30.8049409 C43.4788802,31.2682637 42.8483631,31.555 42.1518125,31.555 L42.1518125,31.555 L2.6481875,31.555 C1.95163687,31.555 1.32111982,31.2682637 0.864682618,30.8049409 C0.407571648,30.3409341 0.125,29.6998713 0.125,28.991863 L0.125,28.991863 L0.125,3.00804821 C0.125,2.3000474 0.407566008,1.65901185 0.864669294,1.19502821 C1.32110791,0.731719251 1.95163058,0.445 2.6481875,0.445 L2.6481875,0.445 Z" id="Shape" />
                        </g>
                    </g>
                    <path d="M22.6702946,22.893784 C23.5105445,22.893784 24.1917016,23.5749411 24.1917016,24.415191 C24.1917016,25.2554409 23.5105445,25.936598 22.6702946,25.936598 C21.8300447,25.936598 21.1488876,25.2554409 21.1488876,24.415191 C21.1488876,23.5749411 21.8300447,22.893784 22.6702946,22.893784 Z M17.0082266,11.6618456 C17.0082266,8.46050229 19.6992227,5.80233864 23.0281157,6.01156174 C25.8686733,6.18787835 28.1436689,8.46287393 28.3199855,11.3034315 C28.4742903,13.7882431 27.0196598,16.0566426 24.7002701,16.9478604 C24.3909193,17.0667389 24.1910346,17.3480005 24.1910346,17.6644662 L24.1910346,17.6644662 L24.1910346,19.164899 C24.1910346,20.0051266 23.5098551,20.686306 22.6696275,20.686306 C21.8294,20.686306 21.1482205,20.0051266 21.1482205,19.164899 L21.1482205,19.164899 L21.1482205,17.6644662 C21.1482205,16.0782097 22.1140709,14.6819808 23.6087228,14.1075252 C24.6816676,13.6951563 25.3545463,12.6441492 25.2830265,11.4919769 C25.2029095,10.2018789 24.1295943,9.12856361 22.8394963,9.04852076 C21.3197198,8.95365517 20.0510406,10.1511851 20.0510406,11.6618456 C20.0510406,12.5020732 19.3698612,13.1832526 18.5296336,13.1832526 C17.6894061,13.1832526 17.0082266,12.5020732 17.0082266,11.6618456 Z" id="Combined-Shape" fill="#9B9EAE" />
                </g>
            </g>
        </svg>
    );
}

Unknown.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};

Unknown.defaultProps = {
    style: {},
    className: undefined,
};
