import React from 'react';
import PropTypes from 'prop-types';
import { ImageGallery } from '@rainbow-modules/storage';
import getCurrentUser from '../../../redux/services/firebase/get-current-user';
import { getFirestorePath } from '../../../helpers';
import {
    ModalContainer,
    // Description,
} from './styled';

export default function FooterTextarea({ onSelectImage }) {
    const { uid } = getCurrentUser();
    return (
        <ModalContainer>
            {/* <Description>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </Description> */}
            <ImageGallery
                path={getFirestorePath(`/${uid}/images`)}
                allowUpload
                onSelect={onSelectImage}
            />
        </ModalContainer>
    );
}

FooterTextarea.propTypes = {
    onSelectImage: PropTypes.func,
};

FooterTextarea.defaultProps = {
    onSelectImage: () => {},
};
