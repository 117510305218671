import React from 'react';
import PropTypes from 'prop-types';

export default function Checkmark(props) {
    const { className } = props;
    return (
        <svg className={className} width="12px" height="10px" viewBox="0 0 12 10" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Dashboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path d="M11.5797526,2.52766927 L5.26024881,8.84699501 C5.07065837,9.0365855 4.82178754,9.1320038 4.57291667,9.1320038 C4.32404579,9.1320038 4.07517497,9.0365855 3.88558452,8.84699501 L0.725921654,5.68733214 C0.34567263,5.30726117 0.34567263,4.69273883 0.725921654,4.31266786 C1.10599262,3.93241883 1.72033691,3.93241883 2.10058594,4.31266786 L4.57291667,6.78499859 L10.2050883,1.15300499 C10.5851593,0.772755964 11.1995036,0.772755964 11.5797526,1.15300499 C11.9598236,1.53307596 11.9598236,2.14742025 11.5797526,2.52766927 Z" id="checkmark" fill="#44D7B6" fillRule="nonzero" />
            </g>
        </svg>
    );
}

Checkmark.propTypes = {
    className: PropTypes.string,
};
Checkmark.defaultProps = {
    className: undefined,
};
