import React from 'react';
import PropTypes from 'prop-types';

const Jcb = (props) => {
    const { className } = props;
    return (
        <svg className={className} width="45px" height="32px" viewBox="0 0 45 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <g id="mensajes" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="jcb" fillRule="nonzero">
                    <path d="M42.1518125,0.125 C42.84839,0.125 43.4788957,0.411722889 43.9353205,0.875017864 C44.3924376,1.3390155 44.675,1.98006831 44.675,2.68804821 L44.675,2.68804821 L44.675,28.671863 C44.675,29.3798713 44.3924284,30.0209341 43.9353174,30.4849409 C43.4788802,30.9482637 42.8483631,31.235 42.1518125,31.235 L42.1518125,31.235 L2.6481875,31.235 C1.95163687,31.235 1.32111982,30.9482637 0.864682618,30.4849409 C0.407571648,30.0209341 0.125,29.3798713 0.125,28.671863 L0.125,28.671863 L0.125,2.68804821 C0.125,1.9800474 0.407566008,1.33901185 0.864669294,0.87502821 C1.32110791,0.411719251 1.95163058,0.125 2.6481875,0.125 L2.6481875,0.125 Z" id="Shape" stroke="#E3E5ED" strokeWidth="0.25" fill="#F3F2F2" />
                    <g id="Group-45" transform="translate(7.000000, 6.000000)">
                        <path d="M1.42953586,20.4423629 L6.36329114,20.4423629 C8.09805907,20.4423629 10.1037975,18.8162025 10.1037975,16.7018565 L10.1037975,4.61852778e-14 L5.33324895,4.61852778e-14 C3.11063291,4.61852778e-14 1.42953586,1.40919831 1.42953586,3.84911392 L1.42953586,20.4423629 Z" id="Path" fill="#0F549D" />
                        <path d="M11.1884388,20.4423629 L16.1221941,20.4423629 C17.856962,20.4423629 19.8632911,18.8162025 19.8632911,16.7018565 L19.8632911,4.61852778e-14 L15.0921519,4.61852778e-14 C12.8688608,4.61852778e-14 11.1884388,1.40919831 11.1884388,3.84911392 L11.1884388,20.4423629 L11.1884388,20.4423629 Z" id="Path" fill="#B41F36" />
                        <path d="M21.0818565,20.4423629 L26.0156118,20.4423629 C27.7503797,20.4423629 29.7567089,18.8162025 29.7567089,16.7018565 L29.7567089,4.61852778e-14 L24.9859072,4.61852778e-14 C22.7632911,4.61852778e-14 21.0821941,1.40919831 21.0821941,3.84911392 L21.0821941,20.4423629 L21.0818565,20.4423629 L21.0818565,20.4423629 L21.0818565,20.4423629 Z" id="Path" fill="#329947" />
                        <g id="Group" transform="translate(0.000000, 7.016793)" fill="#F3F2F2">
                            <path d="M1.40962025,3.7964557 L0.0546835443,3.7964557 C0.434514768,5.31459916 2.22303797,6.5078481 4.22936709,6.5078481 C6.07274262,6.5078481 8.56666667,5.47721519 8.56666667,3.52548523 L8.56666667,0.00168776371 L5.47628692,0.00168776371 L5.47628692,3.52556962 C5.47628692,4.55620253 4.6628692,5.3692827 3.52421941,5.3692827 C2.49459916,5.36894515 1.57257384,4.71848101 1.40962025,3.7964557 Z" id="Path" />
                            <path d="M28.4601688,4.50151899 C28.4601688,5.3142616 27.7560338,5.96506329 26.8340084,5.96506329 L20.0032068,5.96506329 L20.0032068,0.00126582278 L26.8340084,0.00126582278 C27.6467511,0.00126582278 28.3524895,0.706329114 28.3524895,1.51974684 C28.3524895,2.33248945 27.6470886,3.0378903 26.8340084,3.0378903 C27.7560338,3.03772152 28.4601688,3.68843882 28.4601688,4.50151899 Z M22.6050633,2.71248945 L24.7197468,2.71248945 C25.2619409,2.71248945 25.749789,2.22464135 25.749789,1.68185654 C25.749789,1.14033755 25.2619409,0.651814346 24.7197468,0.651814346 L22.6050633,0.651814346 L22.6050633,2.71248945 Z M24.7740084,5.31434599 C25.3155274,5.31434599 25.8040506,4.82649789 25.8040506,4.2843038 C25.8040506,3.7421097 25.3156118,3.2542616 24.7740084,3.2542616 L22.6050633,3.2542616 L22.6050633,5.31400844 L24.7740084,5.31400844 L24.7740084,5.31434599 Z" id="Shape" />
                            <path d="M18.3687764,4.93510549 L18.3687764,5.96514768 L13.1637975,5.96514768 C11.5376371,5.96514768 10.1821097,4.60987342 10.1821097,2.98345992 C10.1821097,1.35763713 11.5376371,0.0017721519 13.1637975,0.0017721519 L18.3687764,0.0017721519 L18.3687764,1.0864135 C17.1218565,0.815189873 15.92827,0.65257384 15.3321519,0.65257384 C14.0315612,0.65257384 13.001519,1.68261603 13.001519,2.98379747 C13.001519,4.2849789 14.0315612,5.3150211 15.3321519,5.3150211 C15.92827,5.31434599 17.1218565,5.20632911 18.3687764,4.93510549 Z" id="Path" />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

Jcb.propTypes = {
    className: PropTypes.string,
};
Jcb.defaultProps = {
    className: undefined,
};

export default Jcb;
