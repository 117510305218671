import React from 'react';
import PropTypes from 'prop-types';

export default function TrashIcon(props) {
    const { className } = props;
    return (
        <svg className={className} width="15px" height="19px" viewBox="0 0 15 19" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="contacts_groups-copy-2" transform="translate(-1323.000000, -225.000000)" fill="#01B6F5" fillRule="nonzero">
                    <g id="Group-21" transform="translate(985.000000, 204.000000)">
                        <g id="Group-20">
                            <path d="M347.123077,21.825 C347.920527,21.825 348.575375,22.4355462 348.645675,23.21469 L348.651923,23.3538462 L348.651,23.855 L352.036538,23.8557692 L352.036538,24.8826923 L351.35,24.882 L350.675386,37.6072975 C350.617674,38.3423709 350.074292,38.9318896 349.371579,39.0683739 L349.229008,39.0895392 L349.082769,39.0980769 L341.77635,39.0980769 C340.989934,39.0871865 340.333151,38.5141998 340.20383,37.7485908 L340.185861,37.602872 L339.511,24.882 L338.825,24.8826923 L338.825,23.8557692 L342.209,23.855 L342.209629,23.3516988 C342.218711,22.6115244 342.747267,21.9969584 343.447767,21.8555278 L343.590057,21.8336531 L343.736314,21.825 L347.123077,21.825 Z M350.322,24.882 L340.538,24.882 L341.208415,37.5328637 C341.232925,37.7988161 341.430811,38.0111097 341.682217,38.0604873 L341.778769,38.0712139 L349.078186,38.0712139 C349.345174,38.064219 349.570002,37.8806985 349.635686,37.634644 L349.65263,37.5396648 L350.322,24.882 Z M345.944231,25.8865385 L345.944231,37.0673077 L344.917308,37.0673077 L344.917308,25.8865385 L345.944231,25.8865385 Z M343.225942,25.8865385 L343.924741,37.0673077 L342.897134,37.0673077 L342.198336,25.8865385 L343.225942,25.8865385 Z M348.659759,25.8865385 L347.964455,37.0673077 L346.937088,37.0673077 L347.618416,25.8865385 L348.659759,25.8865385 Z M347.123077,22.8519231 L343.738462,22.8519231 C343.492058,22.8519231 343.287124,23.0294785 343.244625,23.2636248 L343.236538,23.3538462 L343.236,23.855 L347.625,23.855 L347.625,23.3538462 C347.625,23.1074422 347.447445,22.9025085 347.213298,22.8600097 L347.123077,22.8519231 Z" id="trash" />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

TrashIcon.propTypes = {
    className: PropTypes.string,
};
TrashIcon.defaultProps = {
    className: undefined,
};
