import styled from 'styled-components';
import { Badge } from 'react-rainbow-components';
import Pending from '../../icons/pending';

const colorMap = {
    delivered: '#44D7B6',
    received: '#F89618',
    sent: '#01B6F5',
    queued: '#979CAA',
    failed: '#FE4849',
};

export const Status = styled(Badge)`
    color: ${(props) => colorMap[props.value] || '#3F4954'};
    padding-left: 6px;
`;

export const Icon = styled(Pending)`
    width: 12px;
    height: 12px;
    margin-right: 6px;
    margin-bottom: 2px;
`;
