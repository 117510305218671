import styled from 'styled-components';
import { Chart, Table } from 'react-rainbow-components';

export const Container = styled.section`
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
`;

export const Title = styled.h1`
    font-size: 18px;
    line-height: 1.25;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    margin-bottom: 12px;
    margin-top: 12px;
    text-transform: uppercase;
`;

export const Description = styled.h2`
    font-size: 14px;
    line-height: 1.5;
    color: ${(props) => props.theme.rainbow.palette.text.header};
`;

export const Content = styled.div`
    height: 100%;
`;

export const ChartsCard = styled.div`
    margin: 8px 32px 8px 36px;
    padding: 4px 0;
`;

export const BarChart = styled(Chart)`
    height: 230px;
`;

export const TableCard = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: auto;
`;

export const MessagesTable = styled(Table)`
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: auto;
    margin-bottom: 20px;
`;

export const EmptyMessage = styled.div`
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const MessageTitle = styled.h1`
    font-size: 18px;
    font-weight: 800;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    margin: 16px 0 0 0;
    text-align: center;
`;

export const MessageText = styled.h1`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    margin: 0;
    text-align: center;
`;

export const EmptyImage = styled.img`
    height: 45%;
    margin-top: 16px;
`;
