import { useState, useEffect } from 'react';
import firebase from '../../../firebase';
import { getFunctionName } from '../../../helpers';

const getCards = firebase.functions().httpsCallable(getFunctionName('getStripeCustomerCards'));

const useCustomerCards = () => {
    const [cards, setCards] = useState([]);
    const [isLoading, setLoading] = useState(false);
    useEffect(() => {
        (async () => {
            setLoading(true);
            const { data } = await getCards();
            setLoading(false);
            setCards(data);
        })();
    }, []);
    return [cards, isLoading];
};

export default useCustomerCards;
