import React from 'react';
import PropTypes from 'prop-types';
import Checkmark from '../icons/checkmark';
import ErrorCircle from '../icons/errorCircle';
import SendIcon from '../icons/send';
import Pending from '../icons/pending';
import Received from '../icons/received';
import { Status, Icon } from './styled';

const iconMap = {
    delivered: Checkmark,
    received: Received,
    sent: SendIcon,
    queued: Pending,
    failed: ErrorCircle,
};

function StatusBadge(props) {
    const {
        className,
        style,
        value,
    } = props;
    const icon = iconMap[value] || Pending;

    return (
        <Status
            className={className}
            style={style}
            variant="lightest"
            value={value}>
            <Icon as={icon} />
            {value}
        </Status>
    );
}

StatusBadge.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    value: PropTypes.string,
};

StatusBadge.defaultProps = {
    className: undefined,
    style: {},
    value: null,
};

export default StatusBadge;
