import styled from 'styled-components';
import { Link } from 'react-router-dom';

const StyledLink = styled(Link)`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.brand.main};

    &:hover {
        color: ${(props) => props.theme.rainbow.palette.brand.dark};
    }
`;

export default StyledLink;
