import styled from 'styled-components';
import Link from '../../link';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Label = styled.h4`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
`;

export const StyledLink = styled(Link)`
    font-size: 16px;
`;
