import React from 'react';
import { EvertransitApp } from '@nexxtway/react-evertransit';
import { RainbowFirebaseApp } from '@rainbow-modules/app';
import { WhenAuthenticated, WhenNoAuthenticated } from '@rainbow-modules/auth';
import { Spinner } from 'react-rainbow-components';
import { LogoIcon } from './components/initializing-app/styled';
import RenderIfStandaloneMode from './components/render-if-standalone-mode';
import RenderIfEtMode from './components/render-if-et-mode';
import AuthRoutes from './pages/auth';
import PublicRoutes from './pages/public';
import reducers from './redux/reducers';
import translations from './i18n/translations.json';
import firebase from './firebase';

function InitializingApp() {
    return (
        <Spinner type="arc" variant="brand" size="x-large">
            <LogoIcon />
        </Spinner>
    );
}

export default function App() {
    return (
        <>
            <RenderIfStandaloneMode>
                <RainbowFirebaseApp
                    app={firebase}
                    locale="en"
                    spinner={<InitializingApp />}
                    reducers={reducers}
                    translations={translations}
                >
                    <WhenAuthenticated path="/" redirect="/home/signin">
                        <AuthRoutes />
                    </WhenAuthenticated>
                    <WhenNoAuthenticated path="/home">
                        <PublicRoutes />
                    </WhenNoAuthenticated>
                </RainbowFirebaseApp>
            </RenderIfStandaloneMode>
            <RenderIfEtMode>
                <EvertransitApp app={firebase} reducers={reducers} translations={translations}>
                    <AuthRoutes />
                </EvertransitApp>
            </RenderIfEtMode>
        </>
    );
}
