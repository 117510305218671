import React from 'react';
import PropTypes from 'prop-types';

export default function Send(props) {
    const { className } = props;
    return (
        <svg className={className} width="13px" height="11px" viewBox="0 0 13 11" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Dashboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path d="M4.19820759,7.89235139 L6.2538378,8.67479087 L4.90508438,10.1592321 C4.82627168,10.2461698 4.71658391,10.2924825 4.60445862,10.2924825 C4.5557085,10.2924825 4.50614587,10.2835449 4.45820825,10.2648574 C4.30139536,10.2047322 4.19820759,10.0536069 4.19820759,9.88623144 L4.19820759,9.88623144 L4.19820759,7.89235139 Z M12.4467285,0.0273314926 C12.5921663,-0.0287311492 12.7562917,0.00295643096 12.870042,0.108581698 C12.9846048,0.215019468 13.0276674,0.376707377 12.9821673,0.526207755 L12.9821673,0.526207755 L10.4089733,8.9217915 C10.3764732,9.02904177 10.300098,9.11841699 10.1993478,9.16797962 C10.1432851,9.19560469 10.0823475,9.20941722 10.0205973,9.20941722 C9.9718472,9.20941722 9.92228458,9.1996672 9.87597196,9.18260466 L9.87597196,9.18260466 L4.89939687,7.29028737 L10.9232871,2.12196179 L3.62133113,6.80359864 L0.261635134,5.5255329 C0.104822237,5.46622025 0.000821974079,5.31590737 4.70826247e-06,5.14771945 C-0.000803030032,4.97953152 0.102384731,4.82840614 0.259197628,4.76665598 L0.259197628,4.76665598 Z" id="send" fill="#01B6F5" fillRule="nonzero" />
            </g>
        </svg>
    );
}

Send.propTypes = {
    className: PropTypes.string,
};
Send.defaultProps = {
    className: undefined,
};
